import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";
import { ISitemap } from "../types/ISitemap";

export const useSitemap = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const sitemap = {
    getAll: useAsync(async (): Promise<ISitemap[]> => {
      const response = await api.get<ISitemap[]>("/public/sitemap");
      return response;
    }),

    create: useAsync(async (sitemap: ISitemap): Promise<ISitemap> => {
      auth.validate();
      const response = await api.post<ISitemap>("/moderator/sitemap", sitemap);
      return response;
    }),

    delete: useAsync(async (sitemapId: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/sitemap/${sitemapId}`);
    }),
  };

  return { sitemap };
};
