import React, { useEffect, useState } from "react";
import { message, Table, Select, Tabs } from "antd";
import { useLog } from "../../../../hooks/useLog";
import { ILog } from "../../../../types/ILog";
import { getLogTableColumns } from "./LogTableColumns";
import UniversalLogModal from "./UniversalLogModal";
import { getModalType } from "../../../../utils/LogUtils";
import { IStatus } from "../../../../types/IStatus";

const { TabPane } = Tabs;
const { Option } = Select;

function Changes() {
  const { log } = useLog();
  const [data, setData] = useState<ILog[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("pending");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState<ILog | null>(null);

  const {
    execute: getLogsByStatus,
    loading: loadingStatusLogs,
    // error: errorStatusLogs,
    value: statusLogs,
  } = log.getAllStatus;

  const showModal = (log: ILog) => {
    setSelectedLog(log);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedLog(null);
  };

  const handleStatusChange = async (id: number, newStatusId: number) => {
    try {
      await log.update.execute(id, getStatusName(newStatusId));

      setData((prevData) =>
        prevData.map((log) =>
          log.id === id ? { ...log, status: newStatusId } : log
        )
      );

      message.success("Status został pomyślnie zaktualizowany.");
      fetchLogs();
    } catch (error) {
      message.error("Błąd przy aktualizacji statusu.");
    }
  };

  const getStatusName = (statusId: number) => {
    switch (statusId) {
      case IStatus.ACCEPTED:
        return "ACCEPTED";
      case IStatus.DEPRECATED:
        return "DEPRECATED";
      case IStatus.PENDING:
        return "PENDING";
      default:
        return "";
    }
  };

  const columns = getLogTableColumns(showModal);

  const modifiedColumns = columns.map((col) => {
    if (col.key === "status") {
      return {
        ...col,
        render: (_text: any, record: ILog) => (
          <Select
            value={record.status}
            style={{ width: 120 }}
            onChange={(newStatusId) =>
              handleStatusChange(record.id, newStatusId)
            }
          >
            <Option value={IStatus.ACCEPTED}>Accepted</Option>
            <Option value={IStatus.DEPRECATED}>Deprecated</Option>
            <Option value={IStatus.PENDING}>Pending</Option>
          </Select>
        ),
      };
    }
    return col;
  });

  const getStatusIdByTabKey = (tabKey: string): string => {
    switch (tabKey) {
      case "accepted":
        return "ACCEPTED";
      case "deprecated":
        return "DEPRECATED";
      case "pending":
        return "PENDING";
      default:
        return "PENDING";
    }
  };

  const fetchLogs = () => {
    const statusEnum = getStatusIdByTabKey(activeTabKey);
    getLogsByStatus(statusEnum);
  };

  useEffect(() => {
    fetchLogs();
  }, [activeTabKey]);

  useEffect(() => {
    if (statusLogs) {
      setData(statusLogs);
    }
  }, [statusLogs]);

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Lista zmian</h1>
      <Tabs activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
        <TabPane tab="Pending" key="pending">
          <Table
            columns={modifiedColumns}
            dataSource={data}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ x: 400 }}
            loading={loadingStatusLogs}
          />
        </TabPane>
        <TabPane tab="Accepted" key="accepted">
          <Table
            columns={modifiedColumns}
            dataSource={data}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ x: 800 }}
            loading={loadingStatusLogs}
          />
        </TabPane>
        <TabPane tab="Deprecated" key="deprecated">
          <Table
            columns={modifiedColumns}
            dataSource={data}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ x: 800 }}
            loading={loadingStatusLogs}
          />
        </TabPane>
      </Tabs>

      {selectedLog && getModalType(selectedLog.tableType.name) && (
        <UniversalLogModal
          visible={isModalVisible}
          onClose={handleCancel}
          log={selectedLog}
          type={getModalType(selectedLog.tableType.name)!}
        />
      )}
    </>
  );
}

export default Changes;
