import React, { useState } from "react";
import "./ShopItem.scss";
import AdjustableButton from "../../../../UI/AdjustableButton/AdjustableButton";
import IconTextGradient from "../../../../UI/IconTextGradient/IconTextGradient";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface ShopItemProps {
  images: string[];
  title: string;
  address: string;
  hrefAddress: string;
  openingHours: { day: string; time: string }[];
  phone: string;
  email: string;
  hrefPhone: string;
  hrefEmail: string;
  visitButtonText: string;
  visitButtonHref: string;
}

const ShopItem: React.FC<ShopItemProps> = ({
  images,
  title,
  address,
  hrefAddress,
  openingHours,
  phone,
  email,
  hrefPhone,
  hrefEmail,
  visitButtonText,
  visitButtonHref,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="shop-item">
      <div className="image-container">
        <img
          src={images[currentImageIndex]}
          alt={title}
          className="shop-image"
        />
        <button className="arrow left-arrow" onClick={handlePrevClick}>
          <IoIosArrowBack />
        </button>
        <button className="arrow right-arrow" onClick={handleNextClick}>
          <IoIosArrowForward />
        </button>
        <div className="overlay-details">
          <h2>{title}</h2>
          <a href={hrefAddress} className="address" target="_blank" rel="noreferrer">
            {address}
          </a>
          <div className="shop-opening-hours">
            <div className="hours-title">GODZINY OTWARCIA</div>
            {openingHours.map((hours, index) => (
              <div key={index} className="hours-row">
                <span>{hours.day}</span>
                <span>{hours.time}</span>
              </div>
            ))}
          </div>
          <div className="icon-text-gradient-container">
            <IconTextGradient
              icon="phone"
              text={phone}
              hrefText={hrefPhone}
              gradientDirection="to left"
            />
            <IconTextGradient
              icon="email"
              text={email}
              hrefText={hrefEmail}
              gradientDirection="to left"
            />
          </div>
          <AdjustableButton
            text={visitButtonText}
            backgroundColor="black"
            textColor="white"
            borderColor="white"
            hoverBackgroundColor="white"
            hoverTextColor="black"
            hoverBorderColor="black"
            href={visitButtonHref}
          />
        </div>
      </div>
      <div className="shop-details">
        <h2>{title}</h2>
        <a href={hrefAddress} className="address" target="_blank" rel="noreferrer">
          {address}
        </a>
        <div className="shop-opening-hours">
          <div className="hours-title">GODZINY OTWARCIA</div>
          {openingHours.map((hours, index) => (
            <div key={index} className="hours-row">
              <span>{hours.day}</span>
              <span>{hours.time}</span>
            </div>
          ))}
        </div>
        <div className="icon-text-gradient-container">
          <IconTextGradient
            icon="phone"
            text={phone}
            hrefText={hrefPhone}
            gradientDirection="to left"
          />
          <IconTextGradient
            icon="email"
            text={email}
            hrefText={hrefEmail}
            gradientDirection="to left"
          />
        </div>
        <AdjustableButton
          text={visitButtonText}
          backgroundColor="black"
          textColor="white"
          borderColor="white"
          hoverBackgroundColor="white"
          hoverTextColor="black"
          hoverBorderColor="black"
          href={visitButtonHref}
        />
      </div>
    </div>
  );
};

export default ShopItem;
