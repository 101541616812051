import { EnvironmentFilled } from "@ant-design/icons";
import "./Location.scss";

interface LocationProps {
  location: string;
  address: string;
  position_x: string;
  position_y: string;
  pin_position_x: string;
  pin_position_y: string;
  isSelected: boolean;
  onClick: () => void;
}

export default function Location(props: LocationProps) {
  const {
    location,
    address,
    position_x,
    position_y,
    pin_position_x,
    pin_position_y,
    isSelected,
    onClick,
  } = props;

  const bgColor = isSelected ? "white" : "black";
  const textColor = isSelected ? "black" : "white";
  const iconColor = isSelected ? "#C2A86B" : "white";
  return (
    <>
      <div
        className="location"
        style={{
          top: position_y,
          left: position_x,
          position: "absolute",
          backgroundColor: bgColor,
          color: textColor,
        }}
        onClick={onClick}
      >
        <span>{location}</span>
        <span className="street">{address}</span>
      </div>
      <EnvironmentFilled
        className="location_pin"
        style={{
          color: iconColor,
          top: pin_position_y,
          left: pin_position_x,
          position: "absolute",
        }}
        onClick={onClick}
      />
    </>
  );
}
