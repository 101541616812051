import "./OfferSectionHeading.scss";
import Text from "../../../../UI/Text/Text";

export default function OfferSectionHeading() {
  return (
    <div className="offerSectionHeading">
      <Text
        title={"Poznaj naszą ofertę"}
        text={
          "W M&M Gold znajdziesz wszystko czego potrzebujesz od biżuterii inwestycyjnej po zaręczynową."
        }
        showButton={false}
        align={"text-center"}
        content={""}
        path={""}
      />
    </div>
  );
}
