import { useState, useEffect } from "react";
import { Tabs, Typography } from "antd";
import { TabsProps } from "antd";
import UserProfile from "../../components/Pages/UserPanel/UserProfile/UserProfile";
import Orders from "../../components/Pages/UserPanel/Orders/Orders";
import Wishlist from "../../components/Pages/UserPanel/WishList/WishList";
import Returns from "../../components/Pages/UserPanel/Returns/Returns";
import PasswordChange from "../../components/Pages/UserPanel/PasswordChange/PasswordChange";
import Page from "../../components/UI/Page/Page";
import ChosenForYou from "../../components/Pages/Checkout/ChosenForYou/ChosenForYou";
import RecentlySeen from "../../components/Pages/Home/RecentlySeen/RecentlySeen";
import "./Account.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IUser } from "../../types/IUser";
import { useStorage } from "../../hooks/useStorage";

const { Text } = Typography;

export default function Account() {
  const [activeTab, setActiveTab] = useState("my-data");
  const [isMobile, setIsMobile] = useState(false);
  const [searchParams] = useSearchParams();
  const { storage } = useStorage();
  const navigate = useNavigate();

  const user = storage.session.getJSON<IUser | null>("user");

  useEffect(() => {
    if (!user) {
      console.log("User is not authenticated");
      navigate("/logowanie");
    }
  }, []);

  useEffect(() => {
    const initialTab = searchParams.get("tab");
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [searchParams]);

  const items: TabsProps["items"] = [
    {
      key: "my-data",
      label: "Moje dane",
      children: <UserProfile />,
    },
    {
      key: "orders",
      label: "Moje zamówienia",
      children: <Orders />,
    },
    {
      key: "wishlist",
      label: "Lista życzeń",
      children: <Wishlist />,
    },
    // {
    //   key: "returns",
    //   label: "Moje zwroty",
    //   children: <Returns />,
    // },
    {
      key: "password",
      label: "Zmień hasło",
      children: <PasswordChange />,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 750);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Page>
      <div className="account">
        <header className="account__header">
          <h1>Moje konto</h1>
        </header>

        <div className="account__content">
          <main className="account__main">
            {!isMobile && (
              <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                items={items}
                tabPosition="left"
                className="account-tabs"
              />
            )}

            {isMobile && (
              <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                type="line"
                items={items}
                centered
                size="large"
                defaultActiveKey="1"
                className="account-tabs"
                style={{ justifyContent: "left" }}
              />
            )}
          </main>
        </div>
      </div>
      <ChosenForYou />
      <RecentlySeen />
    </Page>
  );
}
