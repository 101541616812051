import React from "react";
import SignInForm from "./SignInForm";
import Page from "../../components/UI/Page/Page";
import "./SignIn.scss";
import Register from "./Register";

export default function SignIn() {
  return (
    <Page>
      <div className="signIn">
        <h1>Moje konto</h1>
        <p>Zaloguj się lub utwórz konto</p>
        <hr />
        <div className="forms">
          <SignInForm />
          <Register />
        </div>
      </div>
    </Page>
  );
}
