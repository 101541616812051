import { Carousel } from "antd";
import { IProduct } from "../../../../types/IProduct";
import ItemCard from "../../../UI/ItemCard/ItemCard";
import "./ProductPreview.scss";

export default function ProductPreview(props: { product: IProduct }) {
  const { product } = props;

  return (
    <div className="previewContainer">
      <span className="previewTitle">Podgląd karty produktu</span>
      <div className="itemCardContainer">
        <ItemCard
          product={product}
          content={"Kup teraz"}
          showButton={true}
          preview={true}
          variant="small"
        />
      </div>
      <span className="previewTitle">Podgląd karuzeli na stronie produktu</span>
      <Carousel
        arrows
        dots={false}
        infinite={false}
        slidesToShow={2}
        slidesToScroll={1}
        className="productCarousel"
      >
        {product?.attribute?.pictures.map((image) => (
          <img
            src={`data:image/png;base64,${image.directory}`}
            style={{ width: "50%" }}
          />
        ))}
      </Carousel>
    </div>
  );
}
