import { useState, useEffect } from "react";
import "./SearchModal.scss";
import { Input, Modal } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
// import { ReactComponent as MMGoldIcon } from "../../../../icons/MMGOLD.svg";
import { useProduct } from "../../../../hooks/useProduct";
import { IProduct } from "../../../../types/IProduct";
import SearchProduct from "../../SearchProduct/SearchProduct";
import UnderlinedButton from "../../UnderlinedButton/UnderlinedButton";
const { Search } = Input;

export default function SearchModal() {
  const { product } = useProduct();
  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchProducts = async () => {
        if (searchValue) {
          try {
            const response = await product.search.execute(searchValue);
            if (response) {
              const extractedProducts = response.map((item) => item.product);
              setProducts(extractedProducts);
              console.log("WYSZUKIWARKA: ", response);
            }
          } catch (error) {
            console.error("Błąd podczas pobierania produktów:", error);
          }
        } else {
          setProducts([]);
        }
      };
      fetchProducts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return (
    <>
      <SearchOutlined onClick={showModal} style={{ fontSize: "18px" }} />
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="searchModal"
      >
        <Search
          placeholder=""
          enterButton={false}
          size="large"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<SearchOutlined style={{ color: "white" }} />}
          allowClear={{
            clearIcon: <CloseOutlined style={{ color: "white" }} />,
          }}
          className="searchInput"
          variant="borderless"
        />

        {products.length !== 0 && (
          <div className="searchContentMobile">
            {products.slice(0, 5).map((product, index) => {
              return <SearchProduct key={index} product={product} />;
            })}
            <div className="buttonContainer">
              <UnderlinedButton content="Zobacz produkty" path="/produkty" />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
