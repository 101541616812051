import { useContext, useEffect, useState } from "react";
import { notification } from "antd";
import "./WishList.scss";
import ItemCardHover from "../../../UI/ItemCardHover/ItemCardHover";
import { IProduct } from "../../../../types/IProduct";
import { useProduct } from "../../../../hooks/useProduct";
import WishlistContext from "../../../../context/WishlistContext";
import CartContext from "../../../../context/CartContext";

const Wishlist = () => {
  const [visibleCount, setVisibleCount] = useState(6);
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);
  const wishlistCtx = useContext(WishlistContext);
  const wishlist = wishlistCtx.items;
  const cartCtx = useContext(CartContext);

  // function handleAddToCart(product: IProduct) {
  //   if (product) {
  //     cartCtx.addItem(product.id);
  //     notification.success({
  //       message: "Koszyk",
  //       description: "Produkt został dodany do koszyka.",
  //       duration: 2,
  //     });
  //   }
  // }

  useEffect(() => {
    const fetchWishlistProducts = async () => {
      const fetchedProducts: IProduct[] = [];

      for (const id of wishlist) {
        try {
          const response = await product.get.execute(id);
          if (response) {
            fetchedProducts.push(response.product);
          }
        } catch (error) {
          console.error(`Błąd podczas pobierania produktu o ID ${id}:`, error);
        }
      }

      setProducts(fetchedProducts);
      console.log("Produkty z wishlist:", fetchedProducts);
    };

    fetchWishlistProducts();
  }, [wishlist]);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 6);
  };

  return (
    <div className="wishlist">
      <h1 className="wishlist__title">Lista Życzeń</h1>

      {products.length === 0 ? (
        <p className="empty-list">Brak dodanych produktów do listy życzeń</p>
      ) : (
        <div className="wishlist__grid">
          {products.slice(0, visibleCount).map((item) => (
            <ItemCardHover
              product={item}
              key={item.id}
              content="Zobacz szczegóły"
              // path="/"
              // onClick={() => handleAddToCart(item)}
            />
          ))}
        </div>
      )}

      {visibleCount < products.length && products.length > 0 && (
        <div className="show-more-container">
          <p style={{ color: "white" }}>
            {visibleCount} z {products.length} produktów
          </p>
          <button onClick={handleShowMore} className="show-more-button">
            Pokaż więcej
          </button>
        </div>
      )}
    </div>
  );
};

export default Wishlist;
