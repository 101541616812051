import { useEffect, useState } from "react";
import "./BigNav.scss";
import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { IoCallOutline } from "react-icons/io5";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import {
  PiTiktokLogoLight,
  PiInstagramLogoLight,
  PiFacebookLogoLight,
} from "react-icons/pi";
import { ReactComponent as ShoppingBagIcon } from "../../../../icons/shopping-bag.svg";
import { ReactComponent as HeartIcon } from "../../../../icons/heart.svg";
import { ReactComponent as AccountIcon } from "../../../../icons/account.svg";
import { useStorage } from "../../../../hooks/useStorage";

export default function BigNav() {
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("PLN");
  const { storage } = useStorage();

  const handleLanguageClick = (e: any) => {
    e.preventDefault();
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleCurrencyClick = (e: any) => {
    e.preventDefault();
    setIsCurrencyOpen(!isCurrencyOpen);
  };

  const handleCurrencyChange = (flag: any) => {
    setIsCurrencyOpen(flag);
  };

  const handleCurrencySelect = (currency: string) => {
    storage.local.set("currency", currency);
    setSelectedCurrency(currency);
    setIsCurrencyOpen(false);
    window.location.reload();
  };

  const handleLanguageChange = (flag: any) => {
    setIsLanguageOpen(flag);
  };

  const currency: MenuProps["items"] = [
    {
      label: "PLN",
      key: "1",
      onClick: () => handleCurrencySelect("PLN"),
    },
    // {
    //   label: "USD",
    //   key: "2",
    //   onClick: () => handleCurrencySelect("USD"),
    // },
    {
      label: "EUR",
      key: "3",
      onClick: () => handleCurrencySelect("EUR"),
    },
  ];

  const language: MenuProps["items"] = [
    {
      label: "ANGIELSKI",
      key: "1",
    },
    {
      label: "HISZPAŃSKI",
      key: "2",
    },
  ];

  useEffect(() => {
    const currency = storage.local.get("currency");
    if (currency) {
      setSelectedCurrency(currency);
    } else {
      storage.local.set("currency", "PLN");
      setSelectedCurrency("PLN");
    }
  }, []);

  return (
    <div className="bigNav">
      <div className="bigNav_left">
        <span>
          <IoCallOutline
            style={{ marginBottom: "-3px", marginRight: "10px" }}
          />
          <a href="/kontakt">KONTAKT</a>
        </span>

        <span>
          <EnvironmentOutlined
            style={{ marginBottom: "-3px", marginRight: "10px" }}
          />
          <a href="/salony">SALONY</a>
        </span>
      </div>

      <div className="bigNav_center">
        <img
          src={"/images/LOGO22.png"}
          alt={"Logo M&M Gold"}
          height={"100%"}
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/")}
        />
      </div>

      <div className="bigNav_right">
        <div className="bigNav_right_Top">
          <div className="icons">
            {/* <a href="https://www.tiktok.com/@jubilergwiazd">
              <PiTiktokLogoLight className="icon" />
            </a> */}
            <a href="https://www.instagram.com/mmgoldpolska/?locale=pl&hl=en">
              <PiInstagramLogoLight className="icon" />
            </a>
            <a href="https://www.facebook.com/bizuteriazbrylantami/?locale=pl_PL">
              <PiFacebookLogoLight className="icon" />
            </a>
          </div>
          <AdjustableButton text={"UMÓW WIZYTĘ"} href={"/kontakt"} />
          <Dropdown
            menu={{
              items: language,
              style: { backgroundColor: "black" },
            }}
            placement="bottom"
            trigger={["click"]}
            onOpenChange={handleLanguageChange}
          >
            <a onClick={handleLanguageClick}>
              <Space>
                POLSKI
                <DownOutlined
                  className={`dropdown-icon ${isLanguageOpen ? "rotated" : ""}`}
                />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
            menu={{
              items: currency,
              style: { backgroundColor: "black" },
            }}
            placement="bottom"
            trigger={["click"]}
            onOpenChange={handleCurrencyChange}
          >
            <a onClick={handleCurrencyClick}>
              <Space className="dropdown-space">
                {selectedCurrency}
                <DownOutlined
                  className={`dropdown-icon ${isCurrencyOpen ? "rotated" : ""}`}
                />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="bigNav_right_Bottom">
          <button
            onClick={() => (window.location.href = "/koszyk")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <ShoppingBagIcon className="icon" />
          </button>
          <button
            onClick={() => (window.location.href = "/konto?tab=wishlist")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <HeartIcon className="icon" />
          </button>
          <button
            onClick={() => (window.location.href = "/konto")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <AccountIcon className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}
