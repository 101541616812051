import InfoSection from "../../Home/OpeningSection/InfoSection";
import "./JewelryStar.scss";
const JewelryStar = () => {
  return (
    <div className="jewelry-star-about-us">
      <div className="image-container">
        <img src="/images/JewelryStarAboutUs.jpg" alt="Jewelry Star" />
      </div>
      <div className="text-container">
        <h1>JUBILER GWIAZD</h1>
        <p>SPRÓBUJESZ RAZ, POKOCHASZ NA ZAWSZE</p>
      </div>
      <InfoSection />
    </div>
  );
};

export default JewelryStar;
