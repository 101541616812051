import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IUser } from "../types/IUser";
import { IStatus } from "../types/IStatus";

export interface IUserResponse {
  user: IUser;
  status: IStatus;
}

export const useUsers = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const users = {
    getAll: useAsync(async (): Promise<IUser[]> => {
      const response = await api.get<IUser[]>("/admin/user");
      return response;
    }),

    get: useAsync(async (id: number): Promise<IUser> => {
      const response = await api.get<IUser>(`/user/user/${id}`);
      return response;
    }),

    // create: useAsync(async (newUser: IUser): Promise<IUser> => {
    //   auth.validate();
    //   const response = await api.post<IUser>("/admin/user", newUser);
    //   return response;
    // }),

    update: useAsync(async (id: number, updatedUser: IUser): Promise<IUser> => {
      auth.validate();
      const response = await api.put<IUser>(`/user/user/${id}`, updatedUser);
      return response;
    }),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/admin/user/${id}`);
    }),
  };

  return { users };
};
