import Page from "../../components/UI/Page/Page";
import SignUpForm from "./SignUpForm";
import "./SignUp.scss";

export default function SignUp() {
  return (
    <Page>
      <div className="signUp">
        <p className="heading"> REJESTRACJA</p>
        <SignUpForm />
      </div>
    </Page>
  );
}
