import "./Register.scss";

export default function Register() {
  return (
    <div className="register">
      <h2>REJESTRACJA</h2>
      <a href="/rejestracja">
        <button>ZAŁÓŻ KONTO</button>
      </a>
      <p>
        Dołącz do świata Jubilera Gwiazd i zyskaj dostęp do historii zamówień
        oraz listy życzeń.
      </p>
    </div>
  );
}
