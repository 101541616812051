import "./RegulationsHeader.scss";
import Text from "../../../UI/Text/Text";

export default function RegulationsHeader() {
  return (
    <div className="RegulationsHeader">
      <Text
        title={"REGULAMIN"}
        text={""}
        showButton={false}
        align={"text-center"}
        content={""}
        path={""}
      />
    </div>
  );
}
