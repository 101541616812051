import { ReactNode } from "react";
// const TikTokIcon = "../../../icons/TikTok.svg";
import TikTokIcon from "../icons/TikTok.svg";
import FacebookIcon from "../icons/Facebook.svg";
import InstagramIcon from "../icons/Instagram.svg";
import LinkedinIcon from "../icons/Linkedin.svg";
import EnvelopeIcon from "../icons/envelope.svg";
import PhoneIcon from "../icons/phoneGold.svg";
import CallendarIcon from "../icons/callendarGold.svg";
import MarkIcon from "../icons/mark.svg";

interface Link {
  text: string;
  href: string;
  icon?: string;
}

interface FooterSection {
  title: string;
  links: Link[];
}
const footerData: FooterSection[] = [
  {
    title: "O NAS",
    links: [
      { text: "O M&M Gold", href: "/o-nas" },
      { text: "Jubiler Gwiazd", href: "#" },
    ],
  },
  {
    title: "NASZE SALONY",
    links: [
      { text: "Galeria Północna", href: "/salony#galeria-polnocna", icon: MarkIcon },
      { text: "CH Panorama", href: "/salony#galeria-panorama", icon: MarkIcon },
      { text: "CH Modo", href: "/salony#ch-modo", icon: MarkIcon },
      { text: "CH Klif", href: "/salony#ch-klif", icon: MarkIcon },
      { text: "Poznań Stary Browar", href: "/salony#stary-browar", icon: MarkIcon },
    ],
  },
  {
    title: "INFORMACJE",
    links: [
      { text: "Polityka prywatności", href: "/polityka-prywatnosci" },
      { text: "Rozmiary pierścionków", href: "/rozmiary" },
      { text: "Regulamin", href: "/regulamin" },
      { text: "Wiedza o diamentach", href: "/o-diamentach" },
      { text: "FAQ", href: "/czesto-zadawane-pytania" },
    ],
  },
  {
    title: "DOŁĄCZ DO NAS",
    links: [
      { text: "TikTok", href: "https://www.tiktok.com/@jubilergwiazd", icon: TikTokIcon },
      { text: "Instagram", href: "https://www.instagram.com/mmgoldpolska/?locale=pl&hl=en", icon: InstagramIcon },
      { text: "Facebook", href: "https://www.facebook.com/bizuteriazbrylantami/?locale=pl_PL", icon: FacebookIcon },
      { text: "LinkedIn", href: "https://www.linkedin.com/company/mmgold/", icon: LinkedinIcon },
    ],
  },
  {
    title: "KONTAKT",
    links: [
      { text: "+48 787 787 777", href: "tel:+48787787777", icon: PhoneIcon },
      {
        text: "mmgold@mmgold.pl",
        href: "mailto:mmgold@mmgold.pl",
        icon: EnvelopeIcon,
      },
      { text: "Umów spotkanie w salonie", href: "/kontakt", icon: CallendarIcon },
    ],
  },
];

export default footerData;
