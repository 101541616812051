import Page from "../../components/UI/Page/Page";
import Catalog from "./Catalog/Catalog";
import "./Collections.scss";
import Sort from "./Sort/Sort";

export default function Collections() {
  return (
    <Page>
      <div className="CollectionsHeading">
        <h1>KOLEKCJE</h1>
      </div>
      <Sort />
      <Catalog photo={true} number={2} title="INVISIBLE" />
      <Catalog photo={true} number={0} title="INVISIBLE 2" />
    </Page>
  );
}
