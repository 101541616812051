import { useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./AdminLayout.scss";
import { Breadcrumb, Layout, Menu, message } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../../hooks/useAuth";

const breadcrumbNameMap = {
  "/admin": "Dashboard",
  "/admin/produkty": "Produkty",
  "/admin/kolory": "Kolory",
  "/admin/logi": "Logi",
  "/admin/kategoria": "Kategorie",
  "/admin/superkategoria": "Super kategorie",
  "/admin/uzytkownicy": "Użytkownicy",
  "/admin/kolekcje": "Kolekcje",
  "/admin/typy": "Typy",
  "/admin/sitemap": "Sitemap",
  // "/admin/log": "Log",
};

export default function AdminLayout() {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const navigate = useNavigate();
  const { auth } = useAuth();

  const breadcrumbs = useMemo(() => {
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumbTitle =
        breadcrumbNameMap[url as keyof typeof breadcrumbNameMap] || url;
      return {
        title: breadcrumbTitle || url,
        path: url,
      };
    });
    return [{ title: "Home", path: "/" }].concat(extraBreadcrumbItems);
  }, [pathSnippets]);

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };

  const handleLogout = () => {
    auth.signout();
    message.success("Pomyślnie wylogowano");
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "black",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="header"
        >
          <img
            src="/images/MMGoldLogo.png"
            alt="Logo"
            style={{ height: 64, marginRight: 16 }}
          />
          <h1
            style={{
              color: "white",
              fontFamily: "Gotu",
            }}
          >
            Panel Administracyjny
          </h1>
        </div>

        <LogoutOutlined
          onClick={handleLogout}
          style={{
            fontSize: "24px",
            color: "white",
            cursor: "pointer",
            marginLeft: "auto",
          }}
        />
      </Header>
      <Layout>
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["/admin"]}
            selectedKeys={[location.pathname]}
            defaultOpenKeys={["/admin"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={[
              { key: "/admin", label: "Dashboard" },
              { key: "/admin/produkty", label: "Produkty" },
              { key: "/admin/kolory", label: "Kolory" },
              { key: "/admin/sitemap", label: "Sitemap" },
              { key: "/admin/logi", label: "Logi" },
              // { key: "/admin/log", label: "Log" },
              { key: "/admin/reports", label: "Raporty" },
              { key: "/admin/kategoria", label: "Kategorie" },
              { key: "/admin/superkategoria", label: "Super kategorie" },
              { key: "/admin/uzytkownicy", label: "Użytkownicy" },
              { key: "/admin/kolekcje", label: "Kolekcje" },
              { key: "/admin/typy", label: "Typy" },
            ]}
            onClick={handleMenuClick}
          />
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {breadcrumbs.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>{breadcrumb.title}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Content
            className="adminContent"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: "white",
              borderRadius: "10px",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
