import React from "react";
import "./OpeningSection.scss";
import Text from "../../../UI/Text/Text";

const OpeningSection = () => {
  return (
    <div className="opening-section-about-us">
      <div className="text-container">
        <Text
          title="JESTEŚMY TU Z PASJI"
          text="BIŻUTERIA JEST NASZĄ NAJWIĘKSZĄ PASJĄ. STARAMY SIĘ TO OKAZYWAĆ W NASZYCH WSZYSTKICH DZIAŁANIACH"
          showButton={false}
          align="text-center"
          content=""
          path=""
        />
      </div>
    </div>
  );
};

export default OpeningSection;
