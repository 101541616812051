import Page from "../../components/UI/Page/Page";
import "./Cart.scss";
import RecentlySeen from "../../components/Pages/Home/RecentlySeen/RecentlySeen";
import PaymentMethods from "../../components/Pages/Cart/PaymentMethods/PaymentMethods";
import MatchingProducts from "../../components/Pages/Cart/MatchingProducts/MatchingProducts";
import Summary from "../../components/Pages/Cart/Summary/Summary";
import AdjustableButton from "../../components/UI/AdjustableButton/AdjustableButton";
import { useContext, useEffect, useState } from "react";
import CartContext from "../../context/CartContext";

export default function Cart() {
  const cartCtx = useContext(CartContext);
  const cart = cartCtx.items;

  return (
    <Page>
      <div className="summary">
        <h1>KOSZYK</h1>
        <hr />
        <AdjustableButton
          text={"WRÓĆ DO ZAKUPÓW"}
          href={"#"}
          textColor="white"
          borderColor="white"
          backgroundColor="black"
          hoverBackgroundColor="white"
          hoverTextColor="black"
          hoverBorderColor="black"
        />
      </div>
      {cart.length > 0 ? (
        <>
          <Summary />
          <div className="matching">
            <MatchingProducts />
          </div>
        </>
      ) : (
        <div className="emptyCartContainer">
          <p className="emptyCart">Brak produktów w koszyku</p>
        </div>
      )}
      <RecentlySeen />
      <PaymentMethods />
    </Page>
  );
}
