import ShopItem from "./ShopItem/ShopItem";
import "./Shop.scss";

const Shop = () => {
  const shops = [
    {
      images: [
        "/images/Contact-Shop-polnocna1.jpg",
        "/images/Contact-Shop-chklif1.jpg",
        "/images/Contact-Shop-panorama1.jpg",
      ],
      title: "GALERIA PÓŁNOCNA",
      address: "ul. Światowida 17, 03-144 Warszawa",
      hrefAddress: "https://maps.app.goo.gl/qPNghz54jtZPxE3g9",
      openingHours: [
        { day: "Pn-Sb", time: "11:00 - 21:00" },
        { day: "Niedziela handlowa", time: "11:00 - 19:00" },
      ],
      phone: "ZADZWOŃ +48 515 015 715",
      email: "WYŚLIJ E-MAIL",
      hrefPhone: "tel:+48515015715",
      hrefEmail: "mailto:test@siegeventure.com/contact", // nie jestem pewien co do maila
      visitButtonText: "UMÓW WIZYTĘ W SALONIE",
      visitButtonHref: "#",
    },
    {
      images: [
        "/images/Contact-Shop-chklif1.jpg",
        "/images/Contact-Shop-panorama1.jpg",
      ],
      title: "CH KLIF",
      address: "ul. Okopowa 58/72, 01-042 Warszawa",
      hrefAddress: "https://maps.app.goo.gl/e2dQB13vCfsh5d1u5",
      openingHours: [
        { day: "Pn-Sb", time: "11:00 - 21:00" },
        { day: "Niedziela handlowa", time: "11:00 - 19:00" },
      ],
      phone: "ZADZWOŃ +48 515 425 555",
      email: "WYŚLIJ E-MAIL",
      hrefPhone: "tel:+48515425555",
      hrefEmail: "mailto:test@siegeventure.com/contact", // nie jestem pewien co do maila
      visitButtonText: "UMÓW WIZYTĘ W SALONIE",
      visitButtonHref: "#",
    },
    {
      images: [
        "/images/Contact-Shop-chmodo1.jpg",
        "/images/Contact-Shop-chklif1.jpg",
      ],
      title: "CH MODO",
      address: "ul. Łopuszańska 38, 02-220 Warszawa",
      hrefAddress: "https://maps.app.goo.gl/XJZMSMAGx98HgTZv5",
      openingHours: [
        { day: "Pn-Sb", time: "11:00 - 19:00" },
        { day: "Niedziela handlowa", time: "11:00 - 19:00" },
      ],
      phone: "ZADZWOŃ +48 787 787 777",
      email: "WYŚLIJ E-MAIL",
      hrefPhone: "tel:+48787787777",
      hrefEmail: "mailto:test@siegeventure.com/contact", // nie jestem pewien co do maila
      visitButtonText: "UMÓW WIZYTĘ W SALONIE",
      visitButtonHref: "#",
    },
    {
      images: [
        "/images/Contact-Shop-panorama1.jpg",
        "/images/Contact-Shop-chklif1.jpg",
      ],
      title: "GALERIA PANORAMA",
      address: "al. Witosa 31, 01-960 Warszawa",
      hrefAddress: "https://maps.app.goo.gl/7obYddkng1PFBcf78",
      openingHours: [
        { day: "Pn-Sb", time: "11:00 - 21:00" },
        { day: "Niedziela handlowa", time: "11:00 - 19:00" },
      ],
      phone: "ZADZWOŃ +48 500 545 550",
      email: "WYŚLIJ E-MAIL",
      hrefPhone: "tel:+48500545550",
      hrefEmail: "mailto:test@siegeventure.com/contact", // nie jestem pewien co do maila
      visitButtonText: "UMÓW WIZYTĘ W SALONIE",
      visitButtonHref: "#",
    },
  ];

  return (
    <div className="shop">
      {shops.map((shop, index) => (
        <ShopItem
          key={index}
          images={shop.images}
          title={shop.title}
          address={shop.address}
          hrefAddress={shop.hrefAddress}
          openingHours={shop.openingHours}
          phone={shop.phone}
          email={shop.email}
          hrefPhone={shop.hrefPhone}
          hrefEmail={shop.hrefEmail}
          visitButtonText={shop.visitButtonText}
          visitButtonHref={shop.visitButtonHref}
        />
      ))}
    </div>
  );
};

export default Shop;
