import Page from "../../components/UI/Page/Page";
import Text from "../../components/UI/Text/Text";
import "./Sizes.scss";
import { SizesTabs } from "./SizesTabs/SizesTabs";

export default function Sizes() {
  return (
    <Page>
      <div className="top-section">
        <div className="top-section-photo">
          <img src="/images/sizes1.png" className="top-section-photo-img"></img>
        </div>
        <div className="top-section-text">
          <Text
            title={"ROZMIARY I TABELE Z ROZMIARAMI"}
            text={
              "Znalazłeś/aś Swój wymarzony produkt, ale nie wiesz czy będzie na Ciebie pasował? Skorzystaj z opcji po lewej stronie i zamów bezpłatny sizeer do swojego domu lub skorzystaj z tabeli rozmiarów poniżej."
            }
            showButton={false}
            align={"text-left"}
            content={""}
            path={""}
          ></Text>
        </div>
      </div>

      <SizesTabs></SizesTabs>
    </Page>
  );
}
