import React from "react";
import Page from "../../components/UI/Page/Page";
import "./Regulations.scss";
import RegulationsHeader from "../../components/Pages/Regulations/RegulationsHeader/RegulationsHeader";
import RegulationsText from "../../components/Pages/Regulations/RegulationsText/RegulationsText";
export default function Regulations() {
  return (
    <Page>
      <RegulationsHeader />
      <div className="RegulationsText">
        <RegulationsText />
      </div>
    </Page>
  );
}
