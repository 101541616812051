import "./OrderPicture.scss";
export default function OrderPicture() {
  return (
    <div className="OrderPicture">
      <img src="./images/OrderPicture.jpeg" alt="" />
      <div className="text">
        <h1>PRODUKTY DOSTĘPNE NA ZAMÓWIENIE</h1>
        <p>Stwórz swój wymarzony, spersonalizowany produkt</p>
      </div>
    </div>
  );
}
