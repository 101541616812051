import "./AllProductsCategories.scss";
import { Carousel } from "antd";
import AdjustableButton from "../../../components/UI/AdjustableButton/AdjustableButton";
import { useEffect, useState } from "react";

type category = {
  img: string;
  name: string;
  path: string;
};

const categories: category[] = [
  {
    img: "images/naszyjnikiCategory.png",
    name: "NASZYJNIKI",
    path: "/kategorie/naszyjniki",
  },
  {
    img: "images/pierscionkiCategory.png",
    name: "PIERŚCIONKI",
    path: "/kategorie/pierścionki",
  },
  {
    img: "images/zegarkiCategory.png",
    name: "ZEGARKI",
    path: "/kategorie/zegarki",
  },
  {
    img: "images/naszyjnikiCategory.png",
    name: "NASZYJNIKI",
    path: "/kategorie/naszyjniki",
  },
  {
    img: "images/pierscionkiCategory.png",
    name: "PIERŚCIONKI",
    path: "/kategorie/pierścionki",
  },
  {
    img: "images/zegarkiCategory.png",
    name: "ZEGARKI",
    path: "/kategorie/zegarki",
  },
];

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

export default function AllProductsCategories() {
  const width = useWindowWidth();
  let slidesToShow = 4;

  if (width < 650) {
    slidesToShow = 1;
  } else if (width < 992) {
    slidesToShow = 2;
  } else if (width < 1200) {
    slidesToShow = 3;
  }

  return (
    <div className="carouselContainer">
      <Carousel
        arrows
        dots={false}
        infinite={false}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        className="productCarousel"
      >
        {categories.map((category, index) => (
          <div key={index} className="categoryItem">
            <img src={category.img} className="categoryImg" />
            <div className="categoryItemContent">
              <p>{category.name}</p>
              <AdjustableButton
                text={"POKAŻ WSZYSTKO"}
                href={category.path}
                textColor="black"
                borderColor="white"
                backgroundColor="white"
                hoverBackgroundColor="black"
                hoverBorderColor="white"
                hoverTextColor="white"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
