import BankAccount from "../../components/Pages/Contact/BankAccount/BankAccount";
import OpeningSection from "../../components/Pages/Contact/OpeningSection/OpeningSection";
import Departaments from "../../components/Pages/Contact/Departments/Departments";
import Shop from "../../components/Pages/Contact/Shop/Shop";
import Page from "../../components/UI/Page/Page";
import OurPlacesSectionTabs from "../../components/Pages/Home/OurPlacesSection/OurPlacesSectionTabs";

export default function Contact() {
  return (
    <Page>
      <OpeningSection />
      <Departaments />
      <BankAccount />
      <OurPlacesSectionTabs />
      <Shop />
    </Page>
  );
}
