import "./ProductRow.scss";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import { useStorage } from "../../../../hooks/useStorage";

type ProductRowProps = {
  imgSrc: string;
  productName: string;
  productDescription: string;
  price: string;
  href: string;
  reverse?: boolean;
};

export default function ProductRow({
  imgSrc,
  productName,
  productDescription,
  price,
  href,
  reverse = false,
}: ProductRowProps) {
  const { storage } = useStorage();
  const currency = storage.local.get("currency");
  return (
    <div className={`productRow ${reverse ? "reverse" : ""}`}>
      <img src={imgSrc} alt="" />
      <div className="productRowText">
        <div className="text">
          <h2>{productName}</h2>
          <p>{productDescription}</p>
          <h3 className="price">
            {price} {currency}
          </h3>
        </div>
        <AdjustableButton text={"DODAJ DO KOSZYKA"} href={href} />
      </div>
    </div>
  );
}
