import { Form, Input, Button, notification, Col, Row } from "antd";
import { useAuth } from "../../hooks/useAuth";
import "./SignUpForm.scss";
import { SignInRequest, SignUpRequest } from "../../types/IAuth";
import OtpModal from "../../components/UI/OtpModal/OtpModal";
import { useState } from "react";

type NotificationType = "success" | "info" | "warning" | "error";

export default function SignUpForm() {
  const [form] = Form.useForm();
  const { auth } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [otpOpen, setOtpOpen] = useState(false);
  const [signInRequest, setSignInRequest] = useState<SignInRequest>();

  const notify = (type: NotificationType, message: string) => {
    api[type]({ message: message });
  };

  const onFinish = async (signUpRequest: SignUpRequest) => {
    const newSignInRequest = {
      email: signUpRequest?.email || "",
      password: signUpRequest?.password || "",
    };
    setSignInRequest(newSignInRequest);
    try {
      await auth.signup.execute(signUpRequest);
      setOtpOpen(true);
    } catch (err) {
      notify("error", "Niestety twoja rejestracja się nie powiodła!");
    }
  };

  return (
    <>
      {contextHolder}
      <OtpModal
        visible={otpOpen}
        onCancel={() => setOtpOpen(false)}
        signInRequest={signInRequest}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="signupForm"
      >
        <Row gutter={60}>
          <Col xs={24} md={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Proszę podać email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Hasło"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Proszę podać hasło",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Imię"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Proszę podać imię",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={60}>
          <Col xs={24} md={8}>
            <Form.Item
              label="Nazwisko"
              name="surname"
              rules={[
                {
                  required: true,
                  message: "Proszę podać nazwisko",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Numer telefonu"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Proszę podać numer telefonu",
                },
                {
                  pattern: /^[0-9\s-]+$/,
                  message:
                    "Numer telefonu może zawierać tylko cyfry i myślniki",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Kraj"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Proszę podać kraj",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={60}>
          <Col xs={24} md={4}>
            <Form.Item
              label="Miasto"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Proszę podać miasto",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Form.Item
              label="Kod pocztowy"
              name="postalCode"
              rules={[
                {
                  required: true,
                  message: "Proszę podać kod pocztowy",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Ulica"
              name="streetName"
              rules={[
                {
                  required: true,
                  message: "Proszę podać ulicę",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Form.Item
              label="Numer ulicy"
              name="streetNumber"
              rules={[
                {
                  required: true,
                  message: "Proszę podać numer ulicy",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Form.Item
              label="Numer domu"
              name="home"
              rules={[
                {
                  required: true,
                  message: "Proszę podać numer domu",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "center", marginTop: "2rem" }}>
          <Button className="submit" htmlType="submit">
            Załóż konto
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
