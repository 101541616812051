import "./SearchProduct.scss";
import { IProduct } from "../../../types/IProduct";
import { useNavigate } from "react-router-dom";

export default function SearchProduct(props: { product: IProduct }) {
  const { product } = props;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/produkt/${product.id}`);
  };

  return (
    <div className="searchProduct" onClick={handleNavigate}>
      <img
        src={`data:image/png;base64,${product.attribute?.pictures[0].directory}`}
        alt="Ładowanie..."
        className="searchProductImage"
      />
      <div className="searchProductText">
        <p className="productName">{product.attribute?.name}</p>
        <p className="colorText">
          {product.attribute?.colors
            ? product.attribute.colors.map((color) => color.name).join(" / ")
            : ""}
        </p>
      </div>
    </div>
  );
}
