import React from "react";
import Page from "../../components/UI/Page/Page";
import Products from "../../components/Pages/AboutUs/Products/Products";
import JewelryStar from "../../components/Pages/AboutUs/JewelryStar/JewelryStar";
import Details from "../../components/Pages/AboutUs/Details/Details";
import OurPlacesSectionTabs from "../../components/Pages/Home/OurPlacesSection/OurPlacesSectionTabs";
import ShowroomItem from "../../components/Pages/Showrooms/ShowroomItem/ShowroomItem";
import OpeningSection from "../../components/Pages/AboutUs/OpeningSection/OpeningSection";

export default function AboutUs() {
  const productsItems = [
    {
      title: "O M&M GOLD",
      text: "W naszym sklepie znajdą państwo, a także wykonają na zamówienie wymarzony produkt z doradztwem fachowych ekspertów oraz w oparciu o najwyższej klasy materiały dostępne na polskim rynku.",
      image: "/images/ProductsAboutUs-1.jpg",
      path: "#",
      align: "text-right",
    },
    {
      title: "INWESTUJ Z M&M GOLD",
      text: "Jesteśmy dostawcą diamentów do firm jubilerskich i instytucji w całej Polsce. Dostarczamy diamenty inwestycyjne oraz jubilerskie we wszystkich szlifach oraz wielkościach. Gwarantujemy uzyskanie najlepszej ceny na duże certyfikowane brylanty!",
      image: "/images/ProductsAboutUs-2.jpg",
      path: "#",
      align: "text-left",
    },
  ];

  const detailsItems = [
    {
      title: "DBAŁOŚĆ O DETALE",
      text: "W naszym sklepie znajdą państwo, a także wykonają na zamówienie wymarzony produkt z doradztwem fachowych ekspertów oraz w oparciu o najwyższej klasy materiały dostępne na polskim rynku.",
      image: "../images/DetailsAboutUs.jpg",
      path: "#",
      align: "text-right",
    },
    {
      title: "DOŻYWOTNIA GWARANCJA",
      text: "Zapewniamy dożywotnią gwarancję na biżuterię. Gwarancja ta obejmuje wszelkie wady produkcyjne lub problemy wynikające z użytych surowców. Gwarancja odzwierciedla nasze zaangażowanie w stosowanie wyłącznie najwyższej jakości materiałów i precyzyjnego rzemiosła. Wszelkie naprawy lub regulacje w ramach tej gwarancji są przeprowadzane przez doświadczonych jubilerów. Polityka ta zapewnia naszym klientom spokój ducha, którzy wiedzą że ich inwestycja jest chroniona przez całe życie.",
      image: "/images/dozywotniaGwarancja.png",
      path: "#",
      align: "text-left",
    },
  ];

  return (
    <Page>
      <OpeningSection />
      {/* <Products /> */}
      <Details items={productsItems} />
      <JewelryStar />
      <Details items={detailsItems} />
      <OurPlacesSectionTabs />
      <ShowroomItem
        place={"GALERIA PÓŁNOCNA"}
        address={"ul. Światowida 17, 03-144 Warszawa"}
        phone_number={"+48 515 015 715"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={"#"}
        id={"galeria-polnocna"}
        reverse
      />
      <ShowroomItem
        place={"CH KLIF"}
        address={"ul.Okopowa 58/72, 01-042 Warszawa"}
        phone_number={"+48 XXX XXX XXX"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom2.jpeg"}
        vis_path={"#"}
        id={"ch-klif"}
        reverse
      />
      <ShowroomItem
        place={"CH MODO"}
        address={"ul. Łopuszańska 23, 02-220 Warszawa"}
        phone_number={"+48 787 787 777"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={"#"}
        id={"ch-modo"}
        reverse
      />
      <ShowroomItem
        place={"GALERIA PANORAMA"}
        address={"ul. Witosa 31, 00-710 Warszawa"}
        phone_number={"+48 500 545 550"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom3.jpeg"}
        vis_path={""}
        id={"galeria-panorama"}
        reverse
      />
      <ShowroomItem
        place={"STARY BROWAR"}
        address={"ul. Półwiejska 42, 61-888 Poznań"}
        phone_number={"+48 511 445 455"}
        email={""}
        path={"#"}
        hours_week={"09:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={""}
        id={"stary-browar"}
      />
    </Page>
  );
}
