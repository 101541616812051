import { Card, Col, Modal, Row } from "antd";
import { IProduct } from "../../../../../types/IProduct";
import Meta from "antd/es/card/Meta";
import "./PicturesDetails.scss";

type Props = {
  product?: IProduct | null;
};

export default function PicturesDetails({ product }: Props) {
  return (
    <div className="PicturesDetailsContainer">
      <Row gutter={[16, 16]}>
        {product?.attribute?.pictures
          // .concat(product?.attribute?.pictures)
          .map((picture, index) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Card
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                cover={
                  <img
                    alt="Zdjęcie produktu"
                    src={`data:image/${picture.extension};base64,${picture.directory}`}
                  />
                }
              >
                <Meta
                  title={picture.color?.name || "-"}
                  description={`Typ: ${picture.type || "Nieznany"}`}
                />
              </Card>
            </Col>
          ))}
      </Row>
    </div>
  );
}
