import React from "react";
import Page from "../../components/UI/Page/Page";
import ShowroomHeading from "../../components/Pages/Showrooms/ShowroomHeading/ShowroomHeading";
import ShowroomItem from "../../components/Pages/Showrooms/ShowroomItem/ShowroomItem";
import "./Showrooms.scss";
import OurPlacesSectionTabs from "../../components/Pages/Home/OurPlacesSection/OurPlacesSectionTabs";

export default function Showrooms() {
  return (
    <Page>
      <ShowroomHeading />
      <ShowroomItem
        place={"GALERIA PÓŁNOCNA"}
        address={"ul. Światowida 17, 03-144 Warszawa"}
        phone_number={"+48 515 015 715"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={"#"}
        id={"galeria-polnocna"}
      />
      <ShowroomItem
        place={"CH KLIF"}
        address={"ul.Okopowa 58/72, 01-042 Warszawa"}
        phone_number={"+48 XXX XXX XXX"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom2.jpeg"}
        vis_path={"#"}
        id={"ch-klif"}
        reverse
      />
      <ShowroomItem
        place={"CH MODO"}
        address={"ul. Łopuszańska 23, 02-220 Warszawa"}
        phone_number={"+48 787 787 777"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={"#"}
        id={"ch-modo"}
      />
      <ShowroomItem
        place={"GALERIA PANORAMA"}
        address={"ul. Witosa 31, 00-710 Warszawa"}
        phone_number={"+48 500 545 550"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom3.jpeg"}
        vis_path={""}
        id={"galeria-panorama"}
        reverse
      />
      <ShowroomItem
        place={"STARY BROWAR"}
        address={"ul. Półwiejska 42, 61-888 Poznań"}
        phone_number={"+48 511 445 455"}
        email={""}
        path={"#"}
        hours_week={"09:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={""}
        id={"stary-browar"}
      />
      <OurPlacesSectionTabs />
    </Page>
  );
}
