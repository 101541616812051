import React, { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { IColor } from "../../../../types/IColor";
import { ColumnsType } from "antd/lib/table";
import "./Color.scss";
import ColorModal from "./ColorModal";
import { useColor } from "../../../../hooks/useColor";

function Color() {
  const { color } = useColor();
  const [data, setData] = useState<IColor[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentColor, setCurrentColor] = useState<IColor | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllColors,
    loading: loadingColors,
    // error: errorColors,
    value: colors,
  } = color.getAll;

  const {
    execute: createColor,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = color.create;

  const {
    execute: updateColor,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: valueUpdate,
  } = color.update;

  const {
    execute: deleteColor,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = color.delete;

  useEffect(() => {
    getAllColors();
  }, []);

  useEffect(() => {
    if (colors) {
      const extractedColors = colors.map((item) => item.color);
      setData(extractedColors);
    }
  }, [colors]);

  const handleModalSubmit = async (values: IColor) => {
    if (currentColor) {
      try {
        await updateColor(currentColor.id, values);
        message.success("Kolor zaktualizowany");
        getAllColors();
      } catch (err) {
        console.error("Błąd przy aktualizacji koloru:", err);
        message.error("Błąd przy aktualizacji koloru");
      }
    } else {
      try {
        await createColor(values);
        message.success("Kolor dodany");
        getAllColors();
      } catch (err) {
        console.error("Błąd przy dodawaniu koloru:", err);
        message.error("Błąd przy dodawaniu koloru");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteColor(id);
      message.success("Kolor usunięty");
      getAllColors();
    } catch (err) {
      console.error("Błąd przy usuwaniu koloru:", err);
      message.error("Błąd przy usuwaniu koloru");
    }
  };

  const columns: ColumnsType<IColor> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: IColor) => (
        <Space>
          <Button
            className="edit-button"
            onClick={() => {
              setCurrentColor(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            title="Czy na pewno chcesz usunąć tę kolekcję?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  const filteredData = data?.filter((color) =>
    color.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Kolory</h1>
      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj produktu..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentColor(null);
              setIsModalVisible(true);
            }}
            className="add-product-button"
          >
            Dodaj Kolor
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingColors}
        />

        <ColorModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentColor}
          loading={currentColor ? loadingUpdate : loadingCreate}
        />
      </div>
    </>
  );
}

export default Color;
