import React, { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import UserModal from "./UserModal";
import { IUser } from "../../../../types/IUser";
import { useUsers } from "../../../../hooks/useUser";

function Users() {
  const { users } = useUsers();
  const [data, setData] = useState<IUser[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllUsers,
    loading: loadingUsers,
    // error: errorUsers,
    value: fetchedUsers,
  } = users.getAll;

  const {
    execute: updateUser,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: updatedUser,
  } = users.update;

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (fetchedUsers) {
      const formattedData = fetchedUsers.map((item: any) => ({
        id: item.user.id,
        email: item.user.email,
        username: item.user.username,
        authority: item.user.authority,
      }));
      setData(formattedData);
      console.log(formattedData);
    }
  }, [fetchedUsers]);

  const handleModalSubmit = async (values: IUser) => {
    if (currentUser) {
      try {
        await updateUser(currentUser.id!, values);
        message.success("Użytkownik zaktualizowany");
        getAllUsers();
      } catch (err) {
        console.error("Błąd przy aktualizacji użytkownika:", err);
        message.error("Błąd przy aktualizacji użytkownika");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await users.delete.execute(id);
      message.success("Użytkownik został usunięty");
      getAllUsers();
    } catch (error) {
      console.error("Błąd przy usuwaniu użytkownika:", error); // Log the error for debugging
      message.error("Błąd przy usuwaniu użytkownika");
    }
  };

  const columns: ColumnsType<IUser> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa użytkownika",
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Rola",
      dataIndex: "authority",
      key: "authority",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: IUser) => (
        <Space>
          <Button
            key={`edit-${record.id}`}
            onClick={() => {
              setCurrentUser(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            key={`delete-${record.id}`}
            title="Czy na pewno chcesz usunąć tego użytkownika?"
            onConfirm={() => handleDelete(record.id!)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger>Usuń</Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  const filteredData = data?.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Użytkownicy</h1>

      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj użytkownika..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingUsers}
          scroll={{ x: 800 }}
        />

        <UserModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentUser}
          loading={loadingUpdate}
        />
      </div>
    </>
  );
}

export default Users;
