import Page from "../../components/UI/Page/Page";
import PictureHeader from "../../components/UI/PictureHeader/PictureHeader";
import ProductRow from "../../components/Pages/NewPage/ProductRow/ProductRow";
export default function New() {
  return (
    <Page>
      <PictureHeader
        heading="NOWOŚCI"
        description="Poznaj najnowsze produkty dodane do naszej oferty."
        imgSrc="./images/FooterImage.jpeg"
      />
      <ProductRow
        imgSrc={"./images/FooterImage.jpeg"}
        productName={"BRANSOLETKA CAPSULE COLLECTION LIMITED™"}
        productDescription={
          "Bransoletka z limitowanej kolekcji kapsułowej wykonana z żółtego złota Pr.750 zawierająca 49 diamentów o łącznej masie ~0.96ct. Wykonana z materiałów najwyższej jakości oraz według ścisłych procedur marki, co gwarantuje dożywotnią gwarancję."
        }
        price={"24 500"}
        href={"#"}
      />
      <ProductRow
        imgSrc={"./images/FooterImage.jpeg"}
        productName={"BRANSOLETKA CAPSULE COLLECTION LIMITED™"}
        productDescription={
          "Bransoletka z limitowanej kolekcji kapsułowej wykonana z żółtego złota Pr.750 zawierająca 49 diamentów o łącznej masie ~0.96ct. Wykonana z materiałów najwyższej jakości oraz według ścisłych procedur marki, co gwarantuje dożywotnią gwarancję."
        }
        price={"24 500"}
        href={"#"}
        reverse
      />
    </Page>
  );
}
