import { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { ICategory } from "../../../../types/ICategory";
import { useCategory } from "../../../../hooks/useCategory";
import CategoryModal from "./CategoryModal";

function Category() {
  const { category } = useCategory();
  const [data, setData] = useState<ICategory[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<ICategory | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllCategory,
    loading: loadingCategory,
    // error: errorCategory,
    value: categories,
  } = category.getAll;

  const {
    execute: createCategory,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = category.create;

  const {
    execute: updateCategory,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: valueUpdate,
  } = category.update;

  const {
    execute: deleteCategory,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = category.delete;

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    if (categories) {
      const extractedCategories = categories.map((item: any) => item.category);
      setData(extractedCategories);
    }
  }, [categories]);

  const handleModalSubmit = async (values: ICategory) => {
    if (currentCategory) {
      try {
        await updateCategory(currentCategory.id, values);
        message.success("Kategoria zaktualizowana");
        getAllCategory();
      } catch (err) {
        console.error("Błąd przy aktualizacji kategorii:", err);
        message.error("Błąd przy aktualizacji kategorii");
      }
    } else {
      try {
        await createCategory(values);
        message.success("Kategoria dodana");
        getAllCategory();
      } catch (err) {
        console.error("Błąd przy dodawaniu kategorii:", err);
        message.error("Błąd przy dodawaniu kategorii");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteCategory(id);
      message.success("Kategoria usunięta");
      getAllCategory();
    } catch (err) {
      console.error("Błąd przy usuwaniu kategorii:", err);
      message.error("Błąd przy usuwaniu kategorii");
    }
  };

  const columns: ColumnsType<ICategory> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: ICategory) => (
        <Space>
          <Button
            onClick={() => {
              setCurrentCategory(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            title="Czy na pewno chcesz usunąć tę kategorię?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  const filteredData = data?.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Kategorie</h1>

      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj produktu..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentCategory(null);
              setIsModalVisible(true);
            }}
          >
            Dodaj Kategorie
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingCategory}
        />

        <CategoryModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentCategory}
          loading={currentCategory ? loadingUpdate : loadingCreate}
        />
      </div>
    </>
  );
}

export default Category;
