import React, { useState, useEffect, useRef } from "react";
import "./MatchingProducts.scss";
import {
  CustomLeftArrow,
  CustomRightArrow,
} from "../../../UI/Carousel/CustomArrows/CustomArrows";

const items = [
  {
    imageSrc: "./images/matching1.jpeg",
    title: "ZEGAREK ROLEX DATEJUST 36",
  },
  {
    imageSrc: "./images/matching2.jpeg",
    title: "ZEGAREK ROLEX DATEJUST 36",
  },
  {
    imageSrc: "./images/matching3.jpeg",
    title: "ZEGAREK ROLEX DATEJUST 36",
  },
  {
    imageSrc: "./images/matching4.jpeg",
    title: "ZEGAREK ROLEX DATEJUST 36",
  },
];

export default function MatchingProducts() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemWidth = 250;
  const gap = 50;
  const totalItemWidth = itemWidth + gap;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [itemsToShow, setItemsToShow] = useState(0);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        setItemsToShow(Math.floor(carouselWidth / totalItemWidth));
        setCurrentIndex(0);
      }
    };

    updateItemsToShow();

    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, [totalItemWidth]);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < items.length - itemsToShow) {
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, items.length - itemsToShow)
      );
    }
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < items.length - itemsToShow - 1;

  return (
    <div className="matching-products">
      <h1>PODOBNE PRODUKTY</h1>
      <div className="matching-products-carousel" ref={carouselRef}>
        {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${currentIndex * totalItemWidth}px)`,
          }}
          ref={itemsRef}
        >
          {items.map((item, index) => (
            <div
              className="carousel-item"
              key={index}
              style={{ width: itemWidth }}
            >
              <img src={item.imageSrc} alt={item.title} />
              <h3>{item.title}</h3>
            </div>
          ))}
        </div>
        {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
      </div>
    </div>
  );
}
