import React, { useState, useEffect } from "react";
import "./OfferSection.scss";
import OfferSectionHeading from "./OfferSectionHeading/OfferSectionHeading";
import Text from "../../../UI/Text/Text";

export default function OfferSection() {
  const offerSections = [
    {
      title: "HIGH JEWELS",
      text: "Najbardziej ekskluzywna topowa kategoria biżuterii od Jubilera Gwiazd dostępna na specjalne zamówienie. Dodatkowo jesteśmy w stanie wykonać każdy z Twoich wymarzonych projektów, które wspólnie z Tobą może ustalić nasze diamentowe Concierge.",
      image: "/images/Offer-high-jewls.jpeg",
      path: "/kolekcje",
      align: "text-left",
    },
    {
      title: "ZARĘCZYNY",
      text: "W salonie M&M Gold Warszawa przygotowaliśmy największy wybór abyś mógł wybrać najlepszy pierścionek zaręczynowy, które uwiecznią najważniejszą chwilę w życiu. Dzięki naszym niepowtarzalnym projektom, możesz podarować swojej wybrance pierścionek jedyny w swoim rodzaju, który będzie wyjątkowym świadectwem Twoich uczuć!",
      image: "/images/Offer-zareczyny.jpeg",
      path: "/kolekcje",
      align: "text-right",
    },
    {
      title: "BRYLANTY CERTYFIKOWANE",
      text: "W naszym salonie znajdziecie Państwo tylko prawdziwą, brylantową biżuterię. Dzięki naszym niepowtarzalnym projektom, każda kobieta poczuje się wyjątkowo. Spełniamy wszystkie, nawet najbardziej skomplikowane brylantowe marzenia!",
      image: "/images/Offer-Brylanty-certyfikowane.jpeg",
      path: "/kolekcje",
      align: "text-left",
    },
    {
      title: "BRYLANTY INWESTYCYJNE",
      text: "Jesteśmy dostawcą diamentów do firm jubilerskich i instytucji w całej Polsce. Dostarczamy diamenty inwestycyjne oraz jubilerskie we wszystkich szlifach oraz wielkościach. Gwarantujemy uzyskanie najlepszej ceny na duże certyfikowane brylanty!",
      image: "/images/Offer-Brylanty-Inwestycyjne.png",
      path: "/kolekcje",
      align: "text-right",
    },
    {
      title: "BIŻUTERIA DAWNA",
      text: "Biżuteria dawna to nie tylko ozdoba, ale i wyjątkowy kawałek historii. W naszej kolekcji znajdziesz starannie odrestaurowaną biżuterię dawną, która przeniesie Cię w czasie do epoki jej pierwszego blasku. ",
      image: "/images/Offer-Bizuteria_dawna.jpeg",
      path: "/kolekcje",
      align: "text-left",
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1250);
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAlignClass = (index: number) => {
    return index % 2 === 1 ? "row-reverse" : "";
  };

  return (
    <div>
      <OfferSectionHeading />
      <div className="offer-sections">
        {offerSections.map((section, index) => (
          <div className={`offer-section ${getAlignClass(index)}`} key={index}>
            <div className="offer-section-image">
              <img src={section.image} alt={section.title} />
            </div>
            <div className="offer-section-text">
              <Text
                title={section.title}
                text={section.text}
                showButton={true}
                align={isMobile ? "text-center" : section.align}
                content={"Zobacz całą kolekcję"}
                path={section.path}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
