import React, { useEffect, useState } from "react";
import "./SmallNav.scss";
import MobileNav from "../MobileNav/MobileNav";
import { SearchOutlined } from "@ant-design/icons";
import NavDrawer from "../NavDrawer/NavDrawer";
import { ReactComponent as ShoppingBagIcon } from "../../../../icons/shopping-bag.svg";
import { ReactComponent as HeartIcon } from "../../../../icons/heart.svg";
import { ReactComponent as AccountIcon } from "../../../../icons/account.svg";
import SearchDrawer from "../SearchDrawer/SearchDrawer";
import SearchModal from "../SearchModal/SearchModal";

export default function SmallNav() {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [searchVisable, setSearchVisable] = useState(false);

  const handleClose = () => setIsContentVisible(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isContentVisible) {
        setIsContentVisible(false);
        // setTimeout(() => {
        setSelectedKey("");
        // }, 300);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isContentVisible]);

  const toggleContent = (key: string) => {
    if (selectedKey === "") {
      setSelectedKey(key);
      if (!isContentVisible) {
        setSearchVisable(false);
        setTimeout(() => {
          setIsContentVisible(!isContentVisible);
        }, 300);
      } else {
        setIsContentVisible(!isContentVisible);
      }
    } else if (selectedKey === key) {
      setSelectedKey("");
      if (!isContentVisible) {
        setSearchVisable(false);
        setTimeout(() => {
          setIsContentVisible(!isContentVisible);
        }, 300);
      } else {
        setIsContentVisible(!isContentVisible);
      }
    } else {
      setSelectedKey(key);
    }
  };

  const toggleSearch = () => {
    console.log("test");
    if (searchVisable) {
      setSearchVisable(false);
    } else {
      if (isContentVisible) {
        toggleContent(selectedKey);
        setTimeout(() => {
          setSearchVisable(true);
        }, 300);
      } else {
        setSearchVisable(true);
      }
    }
  };

  const navItems = [
    { key: "about", label: "O NAS", href: "/o-nas" },
    { key: "new", label: "NOWOŚCI", href: "/nowe" },
    {
      key: "products",
      label: "PRODUKTY",
      onClick: () => toggleContent("products"),
    },
    {
      key: "collections",
      label: "KOLEKCJE",
      onClick: () => toggleContent("collections"),
    },
    {
      key: "custom-products",
      label: "PRODUKTY NA ZAMÓWIENIE",
      // href: "/zamowienie",
      disabled: true,
    },
    {
      key: "bestsellers",
      label: "BESTSELLERY",
      href: "/kategorie/bestsellers",
    },
    {
      key: "for-him",
      label: "DLA NIEGO",
      href: "/kategorie/dla niego",
      // onClick: () => toggleContent("for-him"),
    },
    { key: "about-diamonds", label: "O DIAMENTACH", href: "/o-diamentach" },
  ];

  return (
    <>
      <nav className="smallNav">
        <div className="smallNav_row">
          <div className="smallNav_mobile">
            <div className="smallNav_mobile_left">
              <MobileNav />
              <SearchModal />
            </div>
            <div className="smallNav_mobile_center">
              <img
                src={"/images/LOGO22.png"}
                alt={"Logo M&M Gold"}
                height={"100%"}
                onClick={() => (window.location.href = "/")}
              />
            </div>
            <div className="smallNav_mobile_right">
              <button
                onClick={() => (window.location.href = "/konto?tab=wishlist")}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                <HeartIcon className="icon" />
              </button>
              <button
                onClick={() => (window.location.href = "/koszyk")}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                <ShoppingBagIcon className="icon" />
              </button>
              <button
                onClick={() => (window.location.href = "/konto")}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                <AccountIcon className="icon" />
              </button>
            </div>
          </div>

          <ul className={"navbar-list"}>
            {navItems.map((item) => (
              <li
                key={item.key}
                className={`navbar-item ${
                  selectedKey === item.key ? "selected" : ""
                } ${item.disabled ? "disabled" : ""} `}
                onClick={item.disabled ? undefined : item.onClick}
              >
                {item.href ? (
                  <a href={item.href}>{item.label}</a>
                ) : (
                  <span>{item.label}</span>
                )}
              </li>
            ))}
          </ul>

          <div className="navbar-divider"></div>

          <div className="navbar-search">
            <SearchModal />
            {/* <SearchOutlined onClick={toggleSearch} /> */}
          </div>
        </div>
        <NavDrawer open={isContentVisible} data={selectedKey} />
        {/* <SearchDrawer open={searchVisable} /> */}
      </nav>
    </>
  );
}
