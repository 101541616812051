import { Modal } from "antd";
import { IProduct } from "../../../../types/IProduct";
import AttributesDetails from "./Details/AttributesDetails";
import PicturesDetails from "./Details/PicturesDetails";

type Props = {
  visible: boolean;
  onCancel: () => void;
  product?: IProduct | null;
};

export default function ProductDetailsModal({
  visible,
  onCancel,
  product,
}: Props) {
  return (
    <Modal
      title={`Szczegóły produktu: ${product?.attribute?.name}`}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={"auto"}
      style={{ whiteSpace: "nowrap" }}
    >
      <PicturesDetails product={product} />
      <AttributesDetails product={product} />
    </Modal>
  );
}
