export const useVisibleFields = () => {
  const trueFields = {
    metal: true,
    goldTest: true,
    stone: true,
    stoneWeight: true,
    clasp: true,
    referenceNumber: true,
    diameter: true,
    mechanism: true,
    dialColor: true,
    productCondition: true,
    included: true,
    productionDate: true,
    colors: true,
    clarity: true,
    cutType: true,
    isRecommended: true,
    seoTitle: true,
    seoDescription: true,
    seoKeywords: true,
    hotjarId: true
  };

  const falseFields = {
    metal: false,
    goldTest: false,
    stone: false,
    stoneWeight: false,
    clasp: false,
    referenceNumber: false,
    diameter: false,
    mechanism: false,
    dialColor: false,
    productCondition: false,
    included: false,
    productionDate: false,
    colors: false,
    clarity: false,
    cutType: false,
    isRecommended: true,
    seoTitle: true,
    seoDescription: true,
    seoKeywords: true,
    hotjarId: true
  };

  const getFields = (category: string) => {
    switch (category) {
      case "diamenty":
        return {
          ...falseFields,
          stoneWeight: true,
          colors: true,
          clarity: true,
          cutType: true,
        };
      case "zegarki":
        return {
          ...falseFields,
          referenceNumber: true,
          diameter: true,
          mechanism: true,
          dialColor: true,
          productCondition: true,
          included: true,
          productionDate: true,
          colors: true,
        };
      case "pierscionki_zareczynowe":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          stoneWeight: true,
          colors: true,
        };
      case "obraczki_z_diamentami":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          colors: true,
        };
      case "naszyjniki_z_diamentami":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          colors: true,
        };
      case "bransoletki_z_diamentami":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          clasp: true,
          colors: true,
        };
      case "kolczyki_z_diamentami":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          clasp: true,
          colors: true,
        };
      case "bizuteria_dawna":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          stoneWeight: true,
          colors: true,
        };
      case "bizuteria_art_deco":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          stoneWeight: true,
          colors: true,
        };
      case "bizuteria_platynowa":
        return {
          ...falseFields,
          stone: true,
          stoneWeight: true,
          colors: true,
        };
      case "high_jewels":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          colors: true,
        };
      case "invisible":
        return {
          ...falseFields,
          metal: true,
          goldTest: true,
          stone: true,
          colors: true,
        };
      default:
        return trueFields;
    }
  };

  return getFields;
};
