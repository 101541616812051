import React from "react";
import "./DiamondsLegality.scss";
import Text from "../../../UI/Text/Text";
import { ReactComponent as HdaIcon } from "../../../../icons/hda.svg";

export default function DiamondLegality() {
  return (
    <div className="diamondLegality-content">
      <div className="diamondLegality-text">
        <Text
          title={"gwarancja legalności"}
          text={
            "Jakość potwierdzona Certyfikatami wystawianymi przez Międzynarodowego Eksperta Diamentów oraz Kamieni Kolorowych z ukończonymi kursami w HRD w Antwerpii. Nasze certyfikaty to gwarancja dobrego wyboru oraz pełnego bezpieczeństwa."
          }
          showButton={false}
          align={"text-right"}
          content={""}
          path={""}
        />
        <HdaIcon />
      </div>
      <div className="diamondLegality-image">
        <img src="./images/certyfikat.png" alt="Certyfikat" />
      </div>
    </div>
  );
}
