import "./OpeningSection.scss";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import InfoSection from "./InfoSection";

export default function OpeningSection() {
  return (
    <section className="opening-section">
      <div className="image-section ">
        <div className="image-content">
          <h1>TWÓJ PIERWSZY KROK</h1>
          <h2>W KIERUNKU LUKSUSU I ELEGANCJI</h2>
          <AdjustableButton
            text="POZNAJ OFERTĘ"
            backgroundColor="transparent"
            textColor="white"
            borderColor="white"
            hoverBackgroundColor="white"
            hoverTextColor="black"
            hoverBorderColor="white"
            href="/produkty"
          />
        </div>
      </div>
      <InfoSection />
    </section>
  );
}
