import React, { useState } from "react";
import "./CollapsibleItem.scss";
import { PlusOutlined } from "@ant-design/icons";

interface CollapsibleItemProps {
  title: string;
  content: React.ReactNode[];
  plus?: boolean;
}

const CollapsibleItem: React.FC<CollapsibleItemProps> = ({
  title,
  content,
  plus,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`collapsible-item ${isOpen ? "open" : ""}`}
      onClick={toggleOpen}
    >
      <div className="collapsible-title">
        {title}
        {plus ? (
          <PlusOutlined className="collapsible-plus" />
        ) : (
          <span className="material-symbols-outlined">
            {isOpen ? "expand_less" : "expand_more"}
          </span>
        )}
      </div>
      {isOpen && (
        <div className="collapsible-content">
          {content.map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CollapsibleItem;
