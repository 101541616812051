import { Navigate } from "react-router-dom";
import { Children } from "../../../../interfaces/children";
import { useStorage } from "../../../../hooks/useStorage";
import { IUser } from "../../../../types/IUser";
import { message } from "antd";

interface ProtectedRouteProps extends Children {
  role?: "ADMIN" | "MODERATOR" | "USER";
}

export const ProtectedRoute = ({ children, role }: ProtectedRouteProps) => {
  const { storage } = useStorage();

  const user = storage.session.getJSON<IUser | null>("user");

  // console.log("test -1:", user);

  if (!user) {
    // console.log("test 0:", user);
    return <Navigate to="/logowanie" />;
  }

  if (user?.authority !== "ADMIN" && user?.authority !== "MODERATOR") {
    // console.log("test 1:", user);
    message.warning("Nie masz uprawnień do tej ścieżki.");
    return <Navigate to="/" />;
  }

  if (role && user?.authority !== role) {
    // console.log("test 2:", user);
    message.warning("Nie masz uprawnień do tej ścieżki.");
    return <Navigate to="/admin" />;
  }

  return <>{children}</>;
};
