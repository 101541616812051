import React from 'react';
import './IconTextGradient.scss';

interface IconTextGradientProps {
    icon: string;
    text: string;
    gradientDirection?: 'to right' | 'to left';
    hrefText?: string;
}

const IconTextGradient: React.FC<IconTextGradientProps> = ({ icon, text, gradientDirection = 'to right', hrefText}) => {
    const gradientStyle = {
        '--gradient-direction': gradientDirection,
    } as React.CSSProperties;

    return (
        <div className="icon-text-gradient" style={gradientStyle}>
            <span className="material-symbols-outlined">{icon}</span>
            {hrefText ? 
                ( 
                    <a className="text" href={hrefText}>
                        {text}
                    </a>
                ) : 
                ( 
                    <span className="text">{text}</span>
                )
            }
        </div>
    );
};

export default IconTextGradient;
