import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import ProductItem from "./ProductItem/ProductItem";
import "./Summary.scss";
import { FiPhone } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";
import { ICartItem } from "../../../../types/ICart";
import { useContext, useEffect, useState } from "react";
import CartContext from "../../../../context/CartContext";
import { IProduct } from "../../../../types/IProduct";
import { useProduct } from "../../../../hooks/useProduct";
import { IColor } from "../../../../types/IColor";
import { ISize } from "../../../../types/ISize";

export default function Summary() {
  const cartCtx = useContext(CartContext);
  const cart = cartCtx.items;
  const { product } = useProduct();
  const [products, setProducts] = useState<
    (IProduct & { quantity: number; color: IColor; size: ISize })[]
  >([]);

  function handleAddToCart(
    product: IProduct & { quantity: number; color: IColor; size: ISize }
  ) {
    cartCtx.addItem(product.id, product.color, product.size);
    product.quantity += 1;
  }

  const handleDeleteItemFromCart = (
    productId: number,
    color: IColor,
    size: ISize
  ) => {
    cartCtx.removeItem(productId, color, size);
    setProducts((prevProducts) =>
      prevProducts.filter(
        (product) =>
          !(
            product.id === productId &&
            product.color.id === color.id &&
            product.size.id === size.id
          )
      )
    );
  };

  const handleDeleteQuantityFromCart = (
    product: IProduct & { quantity: number; color: IColor }
  ) => {
    cartCtx.removeQuantity(product.id);
    product.quantity -= 1;
    if (product.quantity === 0) {
      setProducts((prevProducts) =>
        prevProducts.filter((tabProduct) => tabProduct.id !== product.id)
      );
    }
  };

  useEffect(() => {
    const fetchCartProducts = async () => {
      const fetchedProducts: (IProduct & {
        quantity: number;
        color: IColor;
        size: ISize;
      })[] = [];

      for (const item of cart) {
        try {
          const response = await product.get.execute(item.id);
          if (response) {
            fetchedProducts.push({
              ...response.product,
              quantity: item.quantity, // Dodanie ilości z koszyka
              color: item.color,
              size: item.size,
            });
          }
        } catch (error) {
          console.error(
            `Błąd podczas pobierania produktu o ID ${item.id}:`,
            error
          );
        }
      }

      setProducts(fetchedProducts);
    };

    fetchCartProducts();
  }, []);

  const calculateTotal = (
    products: (IProduct & { quantity: number })[]
  ): number => {
    return products.reduce((total, product) => {
      const price = product.attribute?.price || 0;
      return total + price * product.quantity;
    }, 0);
  };

  const total = calculateTotal(products);

  return (
    <div className="SummaryContent">
      <ProductItem
        products={products}
        handleAddToCart={handleAddToCart}
        handleDeleteItemFromCart={handleDeleteItemFromCart}
        handleDeleteQuantityFromCart={handleDeleteQuantityFromCart}
      />
      <div className="row">
        <div className="gift">
          {/* <input type="checkbox" />
          <p>ZAPAKUJ NA PREZENT</p> */}
        </div>
        <div className="checkout">
          <div className="checkout-row">
            <h2>SUMA ZAMÓWIENIA</h2> <h2>{total}</h2>
          </div>
          <div className="checkout-row">
            <h2>DOSTAWA</h2> <h2>bezplatne</h2>
          </div>
          <div className="checkout-row">
            <h2>CAŁKOWITY KOSZT ZAMÓWIENIA</h2>
            <h2>{total}</h2>
          </div>
          <AdjustableButton
            text={"przejdź do kasy"}
            href={"/zaplac"}
            backgroundColor="white"
            textColor="black"
            borderColor="black"
            hoverBackgroundColor="black"
            hoverTextColor="white"
            hoverBorderColor="white"
          />
          <p className="call">
            <FiPhone color="white" />
            <a href="tel:+999999999">ZADZWOŃ DO NAS</a>
          </p>
          <p className="visit">
            <CiCalendarDate color="white" />
            <a href="/kontakt">UMÓW WIZYTĘ W SALONIE</a>
          </p>
        </div>
      </div>
    </div>
  );
}
