import { Card } from "antd";
import "./Dashboard.scss";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Witaj, co chcesz dzisiaj zobaczyć?</h1>
      <div className="dashboard-grid">
        <a href="/admin/produkty" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/product.png"
                alt="Lista produktów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista produktów" />
          </Card>
        </a>
        <a href="/admin/uzytkownicy" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/users.png"
                alt="Lista użytkowników"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista użytkowników" />
          </Card>
        </a>
        <a href="/admin/kategoria" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/category.png"
                alt="Lista kategorii"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista kategorii" />
          </Card>
        </a>

        <a href="/admin/log" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/changes.png"
                alt="Lista zmian produktów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista zmian produktów" />
          </Card>
        </a>
        <a href="/admin/reports" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/changes.png" // TODO: change the image
                alt="Lista raportów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista raportów" />
          </Card>
        </a>
        <a href="/admin/superkategoria" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/supercategory.png"
                alt="Lista superkategorii"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista superkategorii" />
          </Card>
        </a>
        <a href="/admin/kolekcje" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/collection.png"
                alt="Lista kolekcji"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista kolekcji" />
          </Card>
        </a>
        <a href="/admin/typy" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/types.png"
                alt="Lista typów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista typów" />
          </Card>
        </a>
        <a href="/admin/kolory" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/color.png"
                alt="Lista kolorów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista kolorów" />
          </Card>
        </a>
      </div>
    </div>
  );
};

export default Dashboard;
