import React, { useEffect } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { IUser } from "../../../../types/IUser";

const { Option } = Select;

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: IUser) => void;
  initialValues?: IUser | null;
  loading: boolean;
};

export default function UserModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        username: initialValues.username,
        email: initialValues.email,
        authority: initialValues.authority,
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  const handleFormSubmit = (values: IUser) => {
    //TO DO
    const userData: IUser = {
      username: values.username,
      email: values.email,
      authority: values.authority,
      id: 0,
      password: "",
      createdAt: "",
      token: "",
      contactData: undefined,
      cart: undefined,
      wishList: undefined,
      previousVersionId: 0
    };
    onSubmit(userData);
  };

  return (
    <Modal
      title="Edytuj użytkownika"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="username"
          label="Nazwa użytkownika"
          rules={[{ required: true, message: "Wprowadź nazwę użytkownika" }]}
        >
          <Input placeholder="Wprowadź nazwę użytkownika" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Wprowadź email" }]}
        >
          <Input placeholder="Wprowadź email" type="email" />
        </Form.Item>
        <Form.Item
          name="authority"
          label="Rola"
          rules={[{ required: true, message: "Wybierz rolę" }]}
        >
          <Select placeholder="Wybierz rolę">
            <Option value="ADMIN">Admin</Option>
            <Option value="MODERATOR">Moderator</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Zaktualizuj użytkownika
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
