import { useState, useEffect } from "react";
import Text from "../../../UI/Text/Text";
import "./Details.scss";

interface DetailsItemProps {
  title: string;
  text: string;
  image: string;
  path: string;
  align: string;
}

interface DetailsProps {
  items: DetailsItemProps[];
}

export default function Details(props: DetailsProps) {
  const { items } = props;

  const Details = [
    {
      title: "DBAŁOŚĆ O DETALE",
      text: "W naszym sklepie znajdą państwo, a także wykonają na zamówienie wymarzony produkt z doradztwem fachowych ekspertów oraz w oparciu o najwyższej klasy materiały dostępne na polskim rynku.",
      image: "../images/DetailsAboutUs.jpg",
      path: "#",
      align: "text-right",
    },
    {
      title: "DOŻYWOTNIA GWARANCJA",
      text: "Zapewniamy dożywotnią gwarancję na biżuterię. Gwarancja ta obejmuje wszelkie wady produkcyjne lub problemy wynikające z użytych surowców. Gwarancja odzwierciedla nasze zaangażowanie w stosowanie wyłącznie najwyższej jakości materiałów i precyzyjnego rzemiosła. Wszelkie naprawy lub regulacje w ramach tej gwarancji są przeprowadzane przez doświadczonych jubilerów. Polityka ta zapewnia naszym klientom spokój ducha, którzy wiedzą że ich inwestycja jest chroniona przez całe życie.",
      image: "/images/dozywotniaGwarancja.png",
      path: "#",
      align: "text-left",
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1250);
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAlignClass = (index: number) => {
    return index % 2 === 0 ? "row-reverse" : "";
  };

  return (
    <div>
      <div className="details-sections">
        {items.map((section, index) => (
          <div
            className={`details-section ${getAlignClass(index)}`}
            key={index}
          >
            <div className="details-section-image">
              <img src={section.image} alt={section.title} />
            </div>
            <div className="details-section-text">
              <Text
                title={section.title}
                text={section.text}
                showButton={false}
                align={isMobile ? "text-center" : section.align}
                content={"Zobacz całą kolekcję"}
                path={section.path}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
