import "./DiamondKnowledgeSection.scss";
import Text from "../../../UI/Text/Text";

export default function DiamondKnowledgeSection() {
  return (
    <div className="DiamondPhoto">
      <Text
        title={"poszerz swoją wiedzę o DIAMENTACH"}
        text={
          "Odkryj tajemnice diamentów i zgłęb swoją wiedzę na ich temat. Poznaj ich niezwykłą historię, piękno oraz zanurz się w fascynującym świecie tych kamieni szlachetnych."
        }
        showButton
        align={"text-right"}
        content={"POZNAJ SZCZEGÓŁY"}
        path={"/o-diamentach"}
        adjustableButton
      />
    </div>
  );
}
