import Text from "../../../components/UI/Text/Text";
import "./ProductDescription.scss";
import { IProduct } from "../../../types/IProduct";

export default function ProductDescription(props: {
  product: IProduct | undefined;
}) {
  const { product } = props;

  return (
    <div className="ProductDescription_section">
      <div className="text">
        <Text
          title={"OPIS PRODUKTU"}
          text={product?.attribute?.description ?? ""}
          showButton={false}
          align={"text-center"}
          content={""}
          path={""}
        />
      </div>
      <img
        src={`data:image/png;base64,${product?.attribute?.pictures[0].directory}`}
        alt="Zdjęcie produktu"
      />
    </div>
  );
}
