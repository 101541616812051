import React from "react";
import "./DepartmentItem.scss";
import IconTextGradient from "../../../../UI/IconTextGradient/IconTextGradient";

interface DepartmentItemProps {
  title: string;
  phone: string;
}

const DepartmentItem: React.FC<DepartmentItemProps> = ({ title, phone }) => {
  return (
    <div className="department-item">
      <div className="department-title">{title}</div>
      <a href={`tel:${phone.replace(/\s+/g, '')}`}>
        <IconTextGradient icon="phone" text={`ZADZWOŃ POD ${phone}`} />
      </a>
    </div>
  );
};

export default DepartmentItem;
