import { useEffect, useState } from "react";
import ItemCard from "../../../components/UI/ItemCard/ItemCard";
import "./Catalog.scss";
import { useProduct } from "../../../hooks/useProduct";
import { IProduct } from "../../../types/IProduct";

type CatalogProps = {
  title: string;
  photo: boolean;
  number?: number;
};

export default function Collections({ title, photo, number }: CatalogProps) {
  const [visibleCount, setVisibleCount] = useState(8);
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute();
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 8);
  };

  return (
    <div className="CollectionsPage">
      <div className="CatalogHeading">
        <h1>{title}</h1>
      </div>
      <div className="grid-container">
        {products.slice(0, visibleCount).map((product, index) => {
          if (photo && index === number) {
            return (
              <>
                <div key={index} className="large-image">
                  <img
                    alt="Modelka"
                    src="/images/catalog.png"
                    className="full-image"
                  />
                </div>
                <div key={index} className="grid-item">
                  {/* TO DO */}

                  <ItemCard
                    product={product}
                    key={index}
                    content={"Kup teraz"}
                    showButton={false}
                    variant="catalog"
                  />
                </div>
              </>
            );
          }
          return (
            <div key={index} className="grid-item">
              {/* TO DO */}
              <ItemCard
                product={product}
                key={index}
                content={"Kup teraz"}
                showButton={false}
                variant="catalog"
              />
            </div>
          );
        })}
      </div>
      {visibleCount < products.length && (
        <div className="show-more-container">
          <p style={{ color: "white" }}>
            {visibleCount} z {products.length} produktów
          </p>
          <button onClick={handleShowMore} className="show-more-button">
            Pokaż więcej
          </button>
        </div>
      )}
      <hr></hr>
    </div>
  );
}
