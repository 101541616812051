import { useApi } from "./useApi";
import { ISuperCategory } from "../types/ISuperCategory";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";

export interface ISuperCategoryResponse {
  superCategory: ISuperCategory;
  status: IStatus;
}

export const useSuperCategory = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const supercategory = {
    getAll: useAsync(async (): Promise<ISuperCategory[]> => {
      const response = await api.get<ISuperCategory[]>(
        "/public/super-category"
      );
      return response;
    }),

    get: useAsync(async (id: number): Promise<ISuperCategory> => {
      const response = await api.get<ISuperCategory>(
        `/public/super-category/${id}`
      );
      return response;
    }),

    create: useAsync(
      async (newSuperCategory: ISuperCategory): Promise<ISuperCategory> => {
        auth.validate();
        const response = await api.post<ISuperCategory>(
          "/moderator/super-category",
          newSuperCategory
        );
        return response;
      }
    ),

    update: useAsync(
      async (
        id: number,
        updatedSuperCategory: ISuperCategory
      ): Promise<ISuperCategory> => {
        auth.validate();
        const response = await api.put<ISuperCategory>(
          `/moderator/super-category/${id}`,
          updatedSuperCategory
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/super-category/${id}`);
    }),
  };

  return { supercategory };
};
