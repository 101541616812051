import React, { useEffect, useState } from "react";
import { Button, message, Table, Popconfirm, Input, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import "./Sitemap.scss";
import SitemapModal from "./SitemapModal";
import { ISitemap } from "../../../../types/ISitemap";
import { useSitemap } from "../../../../hooks/useSitemap";

function Sitemap() {
  const { sitemap } = useSitemap();
  const [data, setData] = useState<ISitemap[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSitemap, setCurrentSitemap] = useState<ISitemap | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllSitemaps,
    loading: loadingSitemaps,
    // error: errorSitemaps,
    value: sitemaps,
  } = sitemap.getAll;

  const {
    execute: createSitemap,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = sitemap.create;

  const {
    execute: deleteSitemap,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = sitemap.delete;

  useEffect(() => {
    getAllSitemaps();
  }, []);

  useEffect(() => {
    if (sitemaps && sitemap) {
      setData(sitemaps);
    }
  }, [sitemaps, sitemap]);

  const handleModalSubmit = async (values: ISitemap) => {
    try {
      await createSitemap(values);
      message.success("Url dodany");
      getAllSitemaps();
    } catch (err) {
      console.error("Błąd przy dodawaniu Urlu:", err);
      message.error("Błąd przy dodawaniu Urlu");
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteSitemap(id);
      message.success("Url usunięty");
      getAllSitemaps();
    } catch (err) {
      console.error("Błąd przy usuwaniu Urlu:", err);
      message.error("Błąd przy usuwaniu Urlu");
    }
  };

  const columns: ColumnsType<ISitemap> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Lokalizacja",
      dataIndex: "loc",
      key: "loc",
      align: "center",
    },
    {
      title: "Ostatnia modyfikacja",
      dataIndex: "lastMod",
      key: "lastMod",
      align: "center",
      render: (lastModArray) => {
        if (!lastModArray || lastModArray.length < 6) return "Invalid date";
        const [year, month, day, hour, minute, second] = lastModArray;
        return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
      },
    },
    {
      title: "Częstotliwość odwiedzin",
      dataIndex: "changeFrequency",
      key: "changeFrequency",
      align: "center",
    },
    {
      title: "Priorytet",
      dataIndex: "priority",
      key: "priority",
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: ISitemap) => (
        <Space>
          <Button
            className="edit-button"
            onClick={() => {
              setCurrentSitemap(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>
          <Popconfirm
            title="Czy na pewno chcesz usunąć tę kolekcję?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  console.log("Raw sitemaps from server:", data);
  const filteredData = data?.filter((sitemap) =>
    sitemap.loc.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Sitemap</h1>
      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj zapisu..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentSitemap(null);
              setIsModalVisible(true);
            }}
            className="add-product-button"
          >
            Dodaj Url
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingSitemaps}
        />

        <SitemapModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          loading={loadingCreate}
        />
      </div>
    </>
  );
}

export default Sitemap;
