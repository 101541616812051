import { useEffect, useState } from "react";
import { useApi } from "./useApi";
import { useStorage } from "./useStorage";
import { IUser } from "../types/IUser";
import { useAsync } from "./useAsync";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IStatus } from "../types/IStatus";
import { SignInRequest, SignResponse, SignUpRequest } from "../types/IAuth";

export interface IUserResponse {
  user: IUser;
  status: IStatus;
}

export const useAuth = () => {
  const [user, setUser] = useState<IUser | null>(null);
  const navigate = useNavigate();
  const { storage } = useStorage();
  const { api } = useApi();

  const signin = async (signInRequest: SignInRequest) => {
    const response = await api.post<SignResponse>("/public/auth/signin", {
      ...signInRequest,
    });
    if (!response || !response.user.token) {
      throw new Error("Invalid response from server");
    }
    storage.session.setJSON("user", response.user);
    setUser(response.user);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.user.token}`;
    if (
      response.user.authority === "ADMIN" ||
      response.user.authority === "MODERATOR"
    ) {
      navigate("/admin");
    } else {
      navigate("/");
    }
  };

  const signup = async (signUpRequest: SignUpRequest) => {
    const response = await api.post<IUserResponse>("/public/auth/signup", {
      ...signUpRequest,
    });
    if (!response || !response.user.token) {
      throw new Error("Invalid response from server");
    }
    storage.session.setJSON("user", response.user);
    setUser(response.user);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.user.token}`;
  };

  const signout = () => {
    delete axios.defaults.headers.common["Authorization"];
    storage.session.remove("user");
    setUser(null);
    navigate("/");
  };

  const validate = () => {
    try {
      if (!user || !user.token) {
        throw new Error("Neither user nor token are present in your browser");
      }
      const decodedToken = jwtDecode<JwtPayload>(user.token);
      const currentTime = Date.now() / 1000;

      if (!decodedToken.exp || decodedToken.exp <= currentTime) {
        throw new Error("Token expired");
      }
    } catch (error: any) {
      console.log(error.message);
      signout();
    }
  };

  const auth = {
    signin: useAsync(signin),
    signup: useAsync(signup),
    signout: signout,
    validate: validate,
  };

  useEffect(() => {
    const update = () => {
      try {
        const storedUser = storage.session.getJSON<IUser>("user");
        if (!storedUser || user) {
          return;
        }
        setUser(storedUser);
      } catch (error: any) {
        console.log("Error synchronizing storage and state user", error);
      }
    };

    update();
  }, []); // INIT RENDER ONLY

  return { auth, user };
};
