import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { IColor } from "../../../../types/IColor";
import { useForm } from "antd/es/form/Form";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: IColor) => void;
  initialValues?: IColor | null;
  loading: boolean;
};

export default function ColorModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFormSubmit = (values: IColor) => {
    onSubmit(values);
  };

  return (
    <Modal
      title={initialValues ? "Edytuj kolor" : "Dodaj nowy kolor"}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="name"
          label="Kolor"
          rules={[{ required: true, message: "Wprowadź kolor" }]}
        >
          <Input placeholder="Wprowadź kolor" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {initialValues ? "Zaktualizuj kolor" : "Dodaj kolor"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
