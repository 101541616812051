import Page from "../../components/UI/Page/Page";
import "./Privacy.scss";
import PrivacyHeader from "../../components/Pages/PrivacyPolicy/PrivacyHeader/PrivacyHeader";
import PrivacyText from "../../components/Pages/PrivacyPolicy/PrivacyText/PrivacyText";
export default function PrivacyPolicy() {
  return (
    <Page>
      <PrivacyHeader />
      <div className="PrivacyPolicyText">
        <PrivacyText />
      </div>
    </Page>
  );
}
