import PaymentMethods from "../../components/Pages/Cart/PaymentMethods/PaymentMethods";
import CollapsibleItem from "../../components/UI/CollapsibleItem/CollapsibleItem";
import Page from "../../components/UI/Page/Page";
import PictureHeader from "../../components/UI/PictureHeader/PictureHeader";
import "./Delivery.scss";
import DeliveryMethods from "../../components/Pages/DeliveryPage/DeliveryMethods/DeliveryMethods";
export default function Delivery() {
  return (
    <Page>
      <PictureHeader
        imgSrc={"./images/Delivery.jpeg"}
        heading={"DOSTAWA I PŁATNOŚĆ"}
        description={
          "W M&M GOLD bardzo staramy się dopasować do potrzeb naszych klientów nie tylko poprzez różnorodność oferowanych produktów, ale także poprzez elastyczność procesu zakupowego. Oferujemy kilka metod dostaw oraz płatności."
        }
      />
      <DeliveryMethods />
      <PaymentMethods />
      <div className="bankAccounts">
        <CollapsibleItem
          title="NASZ NUMER KONTA W PLN"
          content={[
            <strong>mBiznes EUR</strong>,
            "PL 91 1140 2004 0000 3912 0480 3856",
            "BIC/SWIFT: BREXPLPWM",
          ]}
        />
        <CollapsibleItem
          title="NASZ NUMER KONTA W EUR"
          content={[
            <strong>mBiznes EUR</strong>,
            "PL 91 1140 2004 0000 3912 0480 3856",
            "BIC/SWIFT: BREXPLPWM",
          ]}
        />
      </div>
    </Page>
  );
}
