import { useState } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import "./MobileNav.scss";

const items = [
  {
    key: "1",
    label: "O NAS",
    href: "/o-nas",
  },
  {
    key: "2",
    label: "NOWOŚCI",
    href: "/nowe",
  },
  {
    key: "3",
    label: "PRODUKTY",
    children: [
      { key: "3.1", label: "NASZYJNIKI", href: "/kategorie/naszyjniki" },
      { key: "3.2", label: "BRANSOLETKI", href: "/kategorie/bransoletki" },
      { key: "3.3", label: "PIERŚCIONKI", href: "/kategorie/pierscionki" },
      { key: "3.4", label: "KOLCZYKI", href: "/kategorie/kolczyki" },
      { key: "3.5", label: "ZEGARKI", href: "/kategorie/zegarki" },
      { key: "3.6", label: "BROSZKI", href: "/kategorie/broszki" },
      { key: "3.7", label: "BRYLANTY", href: "/kategorie/brylanty" },
    ],
  },
  {
    key: "4",
    label: "KOLEKCJE",
    children: [
      { key: "4.1", label: "HIGH JEWELS", href: "/kolekcje" },
      { key: "4.2", label: "INVISIBLE", href: "/kolekcje" },
      { key: "4.3", label: "ZARĘCZYNY", href: "/kolekcje" },
      { key: "4.4", label: "ANTYCZNE", href: "/kolekcje" },
    ],
  },
  {
    key: "5",
    label: "PRODUKTY NA ZAMÓWIENIE",
    href: "",
  },
  {
    key: "6",
    label: "BESTSELLERY",
    href: "/kategorie/bestsellers",
  },
  {
    key: "7",
    label: "DLA NIEGO",
    href: "/kategorie/dla niego",
  },
  {
    key: "8",
    label: "O DIAMENTACH",
    href: "/o-diamentach",
  },
];

export default function MobileNav() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const findItemByKey = (
    items2: typeof items,
    key: string
  ): (typeof items)[0] | undefined => {
    for (const item of items2) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findItemByKey(item.children, key);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  const onClick = (e: any) => {
    const item = findItemByKey(items, e.key);
    console.log(item);

    if (item && item.href) {
      window.location.href = item.href;
    }
  };

  return (
    <div>
      <Button
        type="text"
        icon={<MenuOutlined style={{ fontSize: "18px" }} />}
        onClick={showDrawer}
        style={{
          color: "white",
        }}
      />
      <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={onClose}
        visible={visible}
        className="mobileNav"
      >
        <Menu onClick={onClick} mode="inline" items={items} />
      </Drawer>
    </div>
  );
}
