import { Button, Form, Input, Modal, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { SignInRequest } from "../../../types/IAuth";
import "./OtpModal.scss";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

type NotificationType = "success" | "info" | "warning" | "error";

type Props = {
  visible: boolean;
  onCancel: () => void;
  signInRequest: SignInRequest | undefined;
};

export default function OtpModal({ visible, onCancel, signInRequest }: Props) {
  const [form] = useForm();
  const { auth } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const notify = (type: NotificationType, message: string) => {
    api[type]({ message: message });
  };

  const handleFormSubmit = async (otp: any) => {
    try {
      const newSignInRequest = {
        email: signInRequest?.email || "",
        password: signInRequest?.password || "",
        otp: otp.otp,
      };
      await auth.signin.execute(newSignInRequest);
      notify("success", "Twoja rejestracja zakończyła się sukcesem!");
      navigate("/konto");
    } catch (err) {
      notify("error", "Niestety twoja rejestracja się nie powiodła!");
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      className="otpModal"
    >
      <span className="otpSpanTitle">
        Podaj kod weryfikacyjny
        <span className="otpSpanDescription">
          Aby się zalogować oraz przejść do strony podaj kod weryfikacyjny który
          przesłaliśmy na podany adres e-mail
        </span>
      </span>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        className="otpForm"
      >
        <Form.Item
          name="otp"
          rules={[{ required: true, message: "Wprowadź kod weryfikacyjny" }]}
        >
          <Input placeholder="XXXXXX" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {"Wyślij"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
