import { Card, Tabs } from "antd";
import SizesTable from "../SizesTable/SizesTable";
import "./SizesTabs.scss";

export const SizesTabs = () => {
  const pierscionki = [
    { size: "5", england: "G", us: "4", circumference: "45", diameter: "14,0" },
    {
      size: "6",
      england: "H",
      us: "4+",
      circumference: "46",
      diameter: "14,3",
    },
    { size: "7", england: "", us: "", circumference: "47", diameter: "14,6" },
    { size: "8", england: "I", us: "5", circumference: "48", diameter: "15,0" },
    { size: "9", england: "J", us: "", circumference: "49", diameter: "15,3" },
    {
      size: "10",
      england: "K",
      us: "6-",
      circumference: "50",
      diameter: "15,6",
    },
    {
      size: "11",
      england: "L",
      us: "6",
      circumference: "51",
      diameter: "16,0",
    },
    { size: "12", england: "", us: "", circumference: "52", diameter: "16,3" },
    { size: "13", england: "M", us: "", circumference: "53", diameter: "16,6" },
    {
      size: "14",
      england: "N",
      us: "7",
      circumference: "54",
      diameter: "17,0",
    },
    { size: "15", england: "O", us: "", circumference: "55", diameter: "17,3" },
    {
      size: "16",
      england: "",
      us: "7+",
      circumference: "56",
      diameter: "17,6",
    },
    {
      size: "17",
      england: "P",
      us: "8",
      circumference: "57",
      diameter: "18,0",
    },
    { size: "18", england: "Q", us: "", circumference: "58", diameter: "18,3" },
    {
      size: "19",
      england: "R",
      us: "9",
      circumference: "59",
      diameter: "18,6",
    },
    { size: "20", england: "S", us: "", circumference: "60", diameter: "19,0" },
    {
      size: "21",
      england: "",
      us: "9+",
      circumference: "61",
      diameter: "19,3",
    },
  ];

  const bransoletki = [
    { size: "5", england: "F", us: "3", circumference: "44", diameter: "13,9" },
    {
      size: "6",
      england: "H",
      us: "4+",
      circumference: "47",
      diameter: "14,5",
    },
    { size: "7", england: "I", us: "5", circumference: "49", diameter: "15,0" },
    {
      size: "8",
      england: "J",
      us: "5+",
      circumference: "50",
      diameter: "15,2",
    },
    {
      size: "9",
      england: "K",
      us: "6-",
      circumference: "52",
      diameter: "16,0",
    },
    {
      size: "10",
      england: "L",
      us: "6",
      circumference: "53",
      diameter: "16,3",
    },
    {
      size: "11",
      england: "M",
      us: "6+",
      circumference: "55",
      diameter: "16,8",
    },
    {
      size: "12",
      england: "N",
      us: "7",
      circumference: "56",
      diameter: "17,0",
    },
    {
      size: "13",
      england: "O",
      us: "7+",
      circumference: "58",
      diameter: "17,5",
    },
    {
      size: "14",
      england: "P",
      us: "8",
      circumference: "59",
      diameter: "18,0",
    },
    {
      size: "15",
      england: "Q",
      us: "8+",
      circumference: "61",
      diameter: "18,5",
    },
    {
      size: "16",
      england: "R",
      us: "9",
      circumference: "62",
      diameter: "18,8",
    },
    {
      size: "17",
      england: "S",
      us: "9+",
      circumference: "63",
      diameter: "19,0",
    },
    {
      size: "18",
      england: "T",
      us: "10",
      circumference: "65",
      diameter: "19,5",
    },
    {
      size: "19",
      england: "U",
      us: "10+",
      circumference: "66",
      diameter: "19,8",
    },
    {
      size: "20",
      england: "V",
      us: "11",
      circumference: "68",
      diameter: "20,3",
    },
    {
      size: "21",
      england: "W",
      us: "11+",
      circumference: "69",
      diameter: "20,6",
    },
  ];

  const naszyjniki = [
    {
      size: "5",
      england: "E",
      us: "3-",
      circumference: "43",
      diameter: "13,7",
    },
    { size: "6", england: "G", us: "4", circumference: "45", diameter: "14,2" },
    {
      size: "7",
      england: "H",
      us: "4+",
      circumference: "47",
      diameter: "14,7",
    },
    { size: "8", england: "J", us: "5", circumference: "49", diameter: "15,2" },
    {
      size: "9",
      england: "K",
      us: "5+",
      circumference: "51",
      diameter: "15,7",
    },
    {
      size: "10",
      england: "L",
      us: "6-",
      circumference: "53",
      diameter: "16,2",
    },
    {
      size: "11",
      england: "M",
      us: "6",
      circumference: "55",
      diameter: "16,7",
    },
    {
      size: "12",
      england: "N",
      us: "6+",
      circumference: "57",
      diameter: "17,2",
    },
    {
      size: "13",
      england: "O",
      us: "7",
      circumference: "59",
      diameter: "17,7",
    },
    {
      size: "14",
      england: "P",
      us: "7+",
      circumference: "61",
      diameter: "18,2",
    },
    {
      size: "15",
      england: "Q",
      us: "8",
      circumference: "63",
      diameter: "18,7",
    },
    {
      size: "16",
      england: "R",
      us: "8+",
      circumference: "65",
      diameter: "19,2",
    },
    {
      size: "17",
      england: "S",
      us: "9",
      circumference: "67",
      diameter: "19,7",
    },
    {
      size: "18",
      england: "T",
      us: "9+",
      circumference: "69",
      diameter: "20,2",
    },
    {
      size: "19",
      england: "U",
      us: "10",
      circumference: "71",
      diameter: "20,7",
    },
    {
      size: "20",
      england: "V",
      us: "10+",
      circumference: "73",
      diameter: "21,2",
    },
    {
      size: "21",
      england: "W",
      us: "11",
      circumference: "75",
      diameter: "21,7",
    },
  ];

  const zegarki = [
    {
      size: "5",
      england: "F",
      us: "3+",
      circumference: "44",
      diameter: "14,1",
    },
    { size: "6", england: "H", us: "4", circumference: "46", diameter: "14,6" },
    {
      size: "7",
      england: "I",
      us: "4+",
      circumference: "48",
      diameter: "15,1",
    },
    { size: "8", england: "J", us: "5", circumference: "50", diameter: "15,6" },
    {
      size: "9",
      england: "K",
      us: "5+",
      circumference: "52",
      diameter: "16,1",
    },
    {
      size: "10",
      england: "L",
      us: "6",
      circumference: "54",
      diameter: "16,6",
    },
    {
      size: "11",
      england: "M",
      us: "6+",
      circumference: "56",
      diameter: "17,1",
    },
    {
      size: "12",
      england: "N",
      us: "7",
      circumference: "58",
      diameter: "17,6",
    },
    {
      size: "13",
      england: "O",
      us: "7+",
      circumference: "60",
      diameter: "18,1",
    },
    {
      size: "14",
      england: "P",
      us: "8",
      circumference: "62",
      diameter: "18,6",
    },
    {
      size: "15",
      england: "Q",
      us: "8+",
      circumference: "64",
      diameter: "19,1",
    },
    {
      size: "16",
      england: "R",
      us: "9",
      circumference: "66",
      diameter: "19,6",
    },
    {
      size: "17",
      england: "S",
      us: "9+",
      circumference: "68",
      diameter: "20,1",
    },
    {
      size: "18",
      england: "T",
      us: "10",
      circumference: "70",
      diameter: "20,6",
    },
    {
      size: "19",
      england: "U",
      us: "10+",
      circumference: "72",
      diameter: "21,1",
    },
    {
      size: "20",
      england: "V",
      us: "11",
      circumference: "74",
      diameter: "21,6",
    },
    {
      size: "21",
      england: "W",
      us: "11+",
      circumference: "76",
      diameter: "22,1",
    },
  ];

  const items = [
    {
      key: "1",
      label: "PIERŚCIONKI",
      children: (
        <SizesTable data={pierscionki} image="Category-Pierscionki.jpeg" />
      ),
    },
    {
      key: "2",
      label: "BRANSOLETKI",
      children: (
        <SizesTable data={bransoletki} image="Offer-Bizuteria_dawna.jpeg" />
      ),
    },
    {
      key: "3",
      label: "NASZYJNIKI",
      children: <SizesTable data={naszyjniki} image="sizes2.png" />,
    },
    {
      key: "4",
      label: "ZEGARKI",
      children: <SizesTable data={zegarki} image="Zegarki_Sizes.png" />,
    },
  ];

  const onChange = () => {
    console.log("rr");
  };

  const content = (
    <Card className="blackCard">
      <h2>TABELA ROZMIARÓW</h2>
      <Tabs
        type="line"
        items={items}
        onChange={onChange}
        centered
        size="large"
        defaultActiveKey="1"
        className="SizesTabs"
        style={{ justifyContent: "center" }}
      />
    </Card>
  );

  return content;
};
