import { useEffect, useState } from "react";
import { Table, Button, Space, message, Input, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { IProduct } from "../../../../types/IProduct";
import ProductsModal from "./ProductsModal";
import { useProduct } from "../../../../hooks/useProduct";
import { ColumnsType } from "antd/es/table";
import ProductDetailsModal from "./ProductDetailsModal";

const ProductsTable = () => {
  const { product } = useProduct();
  const [data, setData] = useState<IProduct[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    execute: getAllProducts,
    loading: loadingProducts,
    // error: errorProducts,
    value: products,
  } = product.getAll;

  const {
    execute: createProduct,
    loading: loadingCreate,
    // error: errorCreate,
    // value: valueCreate,
  } = product.create;

  const {
    execute: updateProduct,
    loading: loadingUpdate,
    // error: errorUpdate,
    // value: valueUpdate,
  } = product.update;

  const {
    execute: deleteProduct,
    loading: loadingDelete,
    // error: errorDelete,
    // value: valueDelete,
  } = product.delete;

  const handleModalSubmit = async (values: IProduct) => {
    if (currentProduct) {
      try {
        await updateProduct(currentProduct.id, values);
        message.success("Produkt zaktualizowany");
        getAllProducts();
      } catch (err) {
        console.error("Błąd przy aktualizacji produktu:", err);
        message.error("Błąd przy aktualizacji produktu");
      }
    } else {
      try {
        console.log("TEST PRODUKTU: ", values);
        await createProduct(values);
        message.success("Produkt dodany");
        getAllProducts();
      } catch (err) {
        console.error("Błąd przy dodawaniu produktu:", err);
        message.error("Błąd przy dodawaniu produktu");
      }
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteProduct(id);
      message.success("Produkt usunięty");
      getAllProducts();
    } catch (err) {
      console.error("Błąd przy usuwaniu produktu:", err);
      message.error("Błąd przy usuwaniu produktu");
    }
  };

  const filteredData = data?.filter((product) =>
    product.attribute?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns: ColumnsType<IProduct> = [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Nazwa produktu",
      dataIndex: ["attribute", "name"],
      key: "name",
      align: "center",
    },
    {
      title: "Super kategoria",
      dataIndex: ["superCategory", "name"],
      key: "superCategory",
      align: "center",
    },
    {
      title: "Kategoria",
      dataIndex: ["category", "name"],
      key: "category",
      align: "center",
    },
    {
      title: "Kolekcja",
      dataIndex: ["collection", "name"],
      key: "collection",
      align: "center",
    },
    {
      title: "Typ",
      dataIndex: ["type", "name"],
      key: "type",
      align: "center",
    },
    {
      title: "Cena",
      dataIndex: ["attribute", "price"],
      key: "price",
      render: (price: number) => `${price} zł`,
      align: "center",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (_text, record: IProduct) => (
        <Space>
          <Button
            className="edit-button"
            onClick={() => {
              setCurrentProduct(record);
              setIsDetailsVisible(true);
            }}
          >
            Szczegóły
          </Button>

          <Button
            className="edit-button"
            onClick={() => {
              setCurrentProduct(record);
              setIsModalVisible(true);
            }}
          >
            Edytuj
          </Button>

          <Popconfirm
            title="Czy na pewno chcesz usunąć ten produkt?"
            onConfirm={() => handleDelete(record.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger loading={loadingDelete}>
              Usuń
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (products) {
      const extractedProducts = products.map((item) => item.product);
      setData(extractedProducts);
    }
  }, [products]);

  return (
    <>
      <h1 style={{ padding: "0px", margin: "0px" }}>Produkty</h1>
      <div className="cms-table">
        <div className="cms-table-header">
          <Input
            placeholder="Szukaj produktu..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
            style={{ width: 250, marginRight: 16 }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentProduct(null);
              setIsModalVisible(true);
            }}
            className="add-product-button"
          >
            Dodaj Produkt
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          loading={loadingProducts}
          style={{ color: "black" }}
        />

        <ProductsModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={handleModalSubmit}
          initialValues={currentProduct}
          loading={currentProduct ? loadingUpdate : loadingCreate}
        />

        <ProductDetailsModal
          visible={isDetailsVisible}
          onCancel={() => setIsDetailsVisible(false)}
          product={currentProduct}
        />
      </div>
    </>
  );
};

export default ProductsTable;
