import "./YourOrder.scss";
import { IProduct } from "../../../../types/IProduct";
import { useStorage } from "../../../../hooks/useStorage";

interface YourOrderProps {
  selectedDeliveryText: string | null;
  selectedPaymentText: string | null;
  products: (IProduct & { quantity: number })[];
  total: number;
}

export default function YourOrder({ selectedDeliveryText, selectedPaymentText, products, total }: YourOrderProps) {
  const { storage } = useStorage();
  const currency = storage.local.get("currency");

  return (
    <div className="YourOrder">
      <h1>TWOJE ZAMOWIENIE</h1>
      <div className="YourOrderCart">
        <h2>PRODUKTY</h2>
        <div className="ProductList">
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left", width: "70px" }}>
                    <img
                      src={`data:image/png;base64,${item.attribute?.pictures[0].directory}`}
                      alt="Ładowanie..."
                      className="ProductImage"
                      style={{ height: "70px" }}
                    />
                  </td>
                  <td>
                    <p>{item.attribute?.name}</p>
                  </td>
                  <td>{item.attribute.colors[0].name}</td>
                  <td>{item.quantity} X</td>
                  <td style={{ textAlign: "right", width: "min-content" }}>
                    {" "}
                    {item.attribute?.price !== undefined
                      ? new Intl.NumberFormat("pl-PL").format(
                          +item.attribute.price
                        )
                      : ""}{" "}
                    {currency}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="Summary">
        <div className="row">
          <p>SUMA ZAMÓWIENIA:</p>
          <p className="answer">
            {total !== undefined
              ? new Intl.NumberFormat("pl-PL").format(+total)
              : ""}{" "}
            {currency}
          </p>
        </div>
        <div className="row">
          <p>DOSTAWA:</p>
          <p className="answer">0 {currency}</p>
        </div>
        <div className="row">
          <p>SPOSÓB DOSTAWY:</p>
          <p className="answer">{selectedDeliveryText}</p>
        </div>
        <div className="row">
          <p>METODA PŁATNOŚCI:</p>
          <p className="answer">{selectedPaymentText}</p>
        </div>
        <div className="row last">
          <p>CAŁKOWITY KOSZT ZAMÓWIENIA:</p>
          <p className="answer">
            {" "}
            {total !== undefined
              ? new Intl.NumberFormat("pl-PL").format(+total)
              : ""}{" "}
            {currency}
          </p>
        </div>
      </div>
    </div>
  );
}
