import "./DeliveryMethods.scss";
import { ReactComponent as VIP } from "../../../../icons/vip.svg";
import { ReactComponent as Delivery } from "../../../../icons/delivery.svg";
import { ReactComponent as Showrooms } from "../../../../icons/showroom.svg";

export default function DeliveryMethods() {
  return (
    <>
      <h3>DOSTĘPNE METODY DOSTAWY</h3>
      <div className="deliveryMethods">
        <div className="deliveryMethodsGroup">
          <Showrooms />
          <h4>REZERWACJA W JEDNYM Z SALONÓW</h4>
          <p>Odbiór w jednym z salonów M&M Gold</p>
        </div>
        <div className="deliveryMethodsGroup">
          <Delivery />
          <h4>SZYBKA PRZESYŁKA KURIERSKA</h4>
          <p>Wysyłka nawet w 24h</p>
        </div>
        <div className="deliveryMethodsGroup">
          <VIP />
          <h4>DOSTAWA LUXURY CONCIERAGE</h4>
          <p>
            Dostawa od 4 do 24h na terenie całej Polski, bezpłatna:przy
            zamówieniach powyżej 25,000 PLN.
          </p>
        </div>
      </div>
    </>
  );
}
