import { useState } from "react";
import { Upload, message, Select, Carousel } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { usePicture } from "../../../../hooks/usePicture";
import { IPicture } from "../../../../types/IPicture";
import { IColor } from "../../../../types/IColor";
import ProductFormSelect from "./ProductFormSelect";
import { IProduct, exampleProduct } from "../../../../types/IProduct";
import { IAttribute } from "../../../../types/IAttribute";
import ProductPreview from "./ProductPreview";

const { Dragger } = Upload;

interface PictureUploadProps {
  onSave: (data: IPicture[]) => void;
}

export default function PictureUpload({ onSave }: PictureUploadProps) {
  const { picture } = usePicture();
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedExt, setSelectedExt] = useState<string | null>(null);
  const [newPictures, setNewPictures] = useState<IPicture[]>();
  const [selectedColor, setSelectedColor] = useState<IColor | undefined>(
    undefined
  );
  const [product, setProduct] = useState<IProduct>(exampleProduct);

  const handleUpload = async (newFiles: any[]) => {
    if (!selectedType || !selectedExt || !selectedColor) {
      message.warning(
        "Proszę wybrać typ, rozszerzenie oraz kolor przed dodaniem zdjęcia."
      );
      return;
    }

    if (newFiles.length === 0) return;

    const fileWrapper = newFiles[newFiles.length - 1];
    const file = fileWrapper.originFileObj as File;

    // console.log("FILE : ", file);
    try {
      const base64String = await picture.encode(file);

      const newPicture: IPicture = {
        id: fileWrapper.uid,
        extension: selectedExt,
        type: selectedType,
        color: selectedColor,
        directory: base64String,
      };

      // console.log("Dodaję zdjęcie: ", newPicture);

      const updatedPictures = [...(newPictures || []), newPicture];

      setNewPictures(updatedPictures);

      setProduct({
        ...product,
        attribute: {
          ...product.attribute,
          pictures: updatedPictures,
        } as IAttribute,
      });

      onSave(updatedPictures.map(({ id, ...rest }) => rest));

      message.success(`${file.name} został pomyślnie przetworzony.`);
    } catch (error) {
      message.error(
        `Wystąpił błąd podczas przetwarzania obrazka ${file.name}.`
      );
    }
  };

  const handleRemove = (file: any) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedPictures = (newPictures || []).filter(
      (_, index) =>
        index !== fileList.findIndex((item) => item.uid === file.uid)
    );
    setNewPictures(updatedPictures);

    // console.log("PO USUNIĘCIU: ", updatedPictures);

    setProduct({
      ...product,
      attribute: {
        ...product.attribute,
        pictures: updatedPictures.map(({ id, ...rest }) => rest),
      } as IAttribute,
    });

    onSave(updatedPictures.map(({ id, ...rest }) => rest));

    message.info(`Plik ${file.name} został usunięty.`);
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file: File) => {
      return false;
    },
    onChange(info: any) {
      const { fileList: newFileList, file } = info;

      setFileList(newFileList);

      if (file.status === "removed") {
        // console.log(`Plik ${file.name} został usunięty.`);
        return;
      }

      handleUpload(newFileList);
    },
    onRemove: handleRemove,
  };

  return (
    <>
      {(!selectedType || !selectedExt || !selectedColor) && (
        <p style={{ lineHeight: "10px" }}>
          Wybierz color, typ oraz rozszerzenie aby dodać zdjęcia
        </p>
      )}
      <label
        style={{ display: "block", lineHeight: "10px", marginTop: "30px" }}
      >
        Typ zdjęcia
      </label>
      <Select
        placeholder="Wybierz typ zdjęcia"
        style={{ width: "90%", lineHeight: "10px" }}
        onChange={(value) => setSelectedType(value)}
        value={selectedType}
      >
        <Select.Option value="1">1</Select.Option>
        <Select.Option value="2">2</Select.Option>
        <Select.Option value="3">3</Select.Option>
        <Select.Option value="4">4</Select.Option>
        <Select.Option value="5">5</Select.Option>
        <Select.Option value="6">6</Select.Option>
        <Select.Option value="7">7</Select.Option>
        <Select.Option value="8">8</Select.Option>
        <Select.Option value="9">9</Select.Option>
        <Select.Option value="10">10</Select.Option>
      </Select>

      <label
        style={{ display: "block", lineHeight: "10px", marginTop: "10px" }}
      >
        Rozszerzenie zdjęcia
      </label>
      <Select
        placeholder="Wybierz rozszerzenie zdjęcia"
        style={{ width: "90%", lineHeight: "10px" }}
        onChange={(value) => setSelectedExt(value)}
        value={selectedExt}
      >
        <Select.Option value="png">png</Select.Option>
        <Select.Option value="jpg">jpg</Select.Option>
      </Select>
      <label
        style={{ display: "block", lineHeight: "10px", marginTop: "10px" }}
      >
        Kolor wyrobu
      </label>
      <ProductFormSelect<IColor>
        placeholder="Wybierz kolor"
        value={selectedColor}
        onChange={setSelectedColor}
        dataType="color"
      />
      <Dragger
        {...props}
        fileList={fileList}
        disabled={!selectedType || !selectedExt || !selectedColor}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Kliknij lub przeciągnij zdjęcie produktu, aby je przesłać
        </p>
        {/* {loadingCreate && <p>Przesyłanie...</p>}
        {errorCreate && <p style={{ color: "red" }}>Błąd: {errorCreate}</p>} */}
      </Dragger>
      {newPictures && newPictures.length > 0 && (
        <ProductPreview product={product} />
      )}
    </>
  );
}
