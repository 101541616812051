import React from "react";
import Page from "../../components/UI/Page/Page";
import "./FAQ.scss";
import FAQHeader from "../../components/Pages/FAQ/FAQHeader/FAQHeader";
import FAQQuestions from "../../components/Pages/FAQ/FAQQuestions/FAQQuestions";

export default function FAQ() {
  return (
    <Page>
      <FAQHeader />
      <div className="questions">
        <FAQQuestions />
      </div>
    </Page>
  );
}
