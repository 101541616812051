import "./ShippingDetails.scss";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { IOrder } from "../../../../types/IOrder";

interface ShippingDetailsProps {
  setSelectedDeliveryText: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedPaymentText: React.Dispatch<React.SetStateAction<string | null>>;
  onSave: (data: IOrder) => void;
}

export default function ShippingDetails({ setSelectedDeliveryText, setSelectedPaymentText, onSave }: ShippingDetailsProps) {
  const [selectedDelivery, setSelectedDelivery] = useState<string | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<string | null>(null);
  const [form] = Form.useForm();

  const onFinish = async (newOrder: IOrder) => {
    onSave(newOrder);
  };

  const handleDeliveryChange = (key: string, message: string) => {
    if (key == selectedDelivery) {
      setSelectedDelivery(null);
      setSelectedDeliveryText(null);
    } else {
      setSelectedDelivery(key);
      setSelectedDeliveryText(message);
      form.setFieldsValue({ deliveryOption: key });
    }
  };

  const handlePaymentChange = (key: string, message: string) => {
    if (key == selectedPayment) {
      setSelectedPayment(null);
      setSelectedPaymentText(null);
    } else {
      setSelectedPayment(key);
      form.setFieldsValue({ method: key });
      setSelectedPaymentText(message);
    }
  };

  return (
    <div className="ShippingDetailsContent">
      <div className="first">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="shippingDetailsForm"
        >
          <p className="sectionLabel">1. Dane do wysyłki</p>
          <Row gutter={60}>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać imię",
                  },
                ]}
              >
                <Input placeholder="Imię" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24} md={12}>
              <Form.Item
                name="surname"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać nazwisko",
                  },
                ]}
              >
                <Input placeholder="Nazwisko" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać email",
                  },
                ]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24} md={12}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać numer telefonu",
                  },
                  {
                    pattern: /^[0-9\s-]+$/,
                    message:
                      "Numer telefonu może zawierać tylko cyfry i myślniki",
                  },
                ]}
              >
                <Input placeholder="Numer telefonu" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24} md={12}>
              <Form.Item
                name="home"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać numer domu",
                  },
                ]}
              >
                <Input placeholder="Numer lokalu" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24} md={6}>
              <Form.Item
                name="streetName"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać ulicę",
                  },
                ]}
              >
                <Input placeholder="Ulica" />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="streetNumber"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać numer ulicy",
                  },
                ]}
              >
                <Input placeholder="Numer ulicy" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24} md={6}>
              <Form.Item
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać kod pocztowy",
                  },
                  {
                    pattern: /^[0-9]{2}-[0-9]{3}$/,
                    message: "Kod pocztowy należy zapisać w postaci **-***, np. 88-888",
                  },
                ]}
              >
                <Input placeholder="Kod pocztowy" />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Proszę podać miasto",
                  },
                ]}
              >
                <Input placeholder="Miasto" />
              </Form.Item>
            </Col>
          </Row>

          {/* Dodatkowe pola */}
          <Row gutter={60}>
            <Col xs={24}>
              <Form.Item name="message">
                <textarea
                  className="note-text"
                  placeholder="Dodaj notatkę..."
                />
              </Form.Item>
            </Col>
          </Row>
          <p className="sectionLabel">2. Wybierz opcję dostawy</p>
          <Row gutter={60}>
            <Col xs={24}>
              <Form.Item
                name="delivery"
                rules={[
                  { required: true, message: "Proszę wybrać opcję dostawy" },
                ]}
              >
                <div>
                  <Row gutter={60} style={{ marginBottom: "20px" }}>
                    <Col xs={24}>
                      <Checkbox
                        checked={selectedDelivery === "RESERVATION"}
                        onClick={(e) => {
                          handleDeliveryChange("RESERVATION", "Rezerwacja w salonie");
                        }}
                        value={"RESERVATION"}
                      >
                        <div className="checkboxText">
                          <span className="optionText">
                            Rezerwacja w salonie{" "}
                            <span className="descriptionText">
                              (Odbiór w jednym z salonów M&M Gold)
                            </span>
                          </span>{" "}
                          <span className="priceText">BEZPŁATNIE</span>
                        </div>
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row gutter={60} style={{ marginBottom: "20px" }}>
                    <Col xs={24}>
                      <Checkbox
                        checked={selectedDelivery === "COURIER"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeliveryChange("COURIER", "Szybka przesyłka kurierska");
                        }}
                        value={"COURIER"}
                      >
                        <div className="checkboxText">
                          <span className="optionText">
                            Szybka przesyłka kurierska
                            <span className="descriptionText">
                              (Wysyłka nawet w 24h)
                            </span>
                          </span>{" "}
                          <span className="priceText">BEZPŁATNIE</span>
                        </div>
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row gutter={60}>
                    <Col xs={24}>
                      <Checkbox
                        checked={selectedDelivery === "CONCIERGE"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeliveryChange("CONCIERGE", "Dostawa Luxury Concierge");
                        }}
                        value={"CONCIERGE"}
                      >
                        <div className="checkboxText">
                          <span className="optionText">
                            Dostawa Luxury Concierge
                            <span className="descriptionText">
                              (od 4 do 24h, bezpłatna powyżej 25 000 PLN)
                            </span>
                          </span>{" "}
                          <span className="priceText">BEZPŁATNIE</span>
                        </div>
                      </Checkbox>
                    </Col>
                  </Row>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={60}>
            <Col xs={24}>
              <p className="sectionLabel">3. Wybierz metodę płatności</p>
              <Form.Item
                name="method"
                rules={[
                  { required: true, message: "Proszę wybrać metodę płatności" },
                ]}
              >
                <div>
                  {/* <Checkbox.Group> */}
                  <Row gutter={60} style={{ marginBottom: "20px" }}>
                    <Col xs={24}>
                      <Checkbox
                        checked={selectedPayment === "PAYU"}
                        onChange={() => handlePaymentChange("PAYU", "Przelew na konto")}
                        value={"PAYU"}
                      >
                        Przelew na konto
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row gutter={60} style={{ marginBottom: "20px" }}>
                    <Col xs={24}>
                      <Checkbox
                        checked={selectedPayment === "SHOP"}
                        onChange={() => handlePaymentChange("SHOP", "Płatność przy odbiorze")}
                        value={"SHOP"}
                      >
                        Płatność przy odbiorze
                      </Checkbox>
                    </Col>
                  </Row>
                </div>
                {/* </Checkbox.Group> */}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={60} style={{ marginBottom: "20px" }}>
            <Col xs={24}>
              <p className="sectionLabel">
                4. Regulamin i polityka prywatności
              </p>
              <Form.Item
                name="isAccepted"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Proszę zapoznać się z regulaminem",
                  },
                ]}
              >
                <Checkbox>
                  Zapoznałam/em się z treścią Regulaminu oraz Polityką
                  prywatności i akceptuję ich treść*
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ textAlign: "center", marginTop: "2rem" }}>
            <Button className="submit" htmlType="submit">
              ZAMÓW
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* <AdjustableButton text={"Kupuję i płacę"} href={"#"} /> */}
    </div>
  );
}
