import "./OurPlacesSection.scss";
import Text from "../../../UI/Text/Text";
import Location from "./Location";
import { useState } from "react";
import { Image } from "antd";

type Location = {
  location: string;
  address: string;
  addressExt: string;
  tel: string;
  img_path: string;
  position_x: string;
  position_y: string;
  pin_position_x: string;
  pin_position_y: string;
};

type OurPlacesProps = {
  locations: Location[];
  city: string;
};

export default function OurPlacesSection({ locations, city }: OurPlacesProps) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationClick = (index: any) => {
    console.log(index);
    setSelectedLocation(index);
  };

  const renderContent = () => {
    if (selectedLocation === null) {
      return <p>Wybierz lokalizację, aby zobaczyć szczegóły.</p>;
    }
    const selected = locations[selectedLocation];
    return (
      <div className="details_container">
        <div className="details_image">
          <Image
            height={"100%"}
            src={selected.img_path}
            alt="Prezentacja salonu w danej lokalizacji"
          />
        </div>
        <div>
          <p>{selected.location}</p>
          <p>
            {selected.address}
            <br></br>
            {selected.addressExt}
            <br></br>
            {selected.tel}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="OurPlacesContainer">
      <div className="Left-side">
        <Text
          title={"Nasze salony"}
          text={
            "Odkryj skarbce M&M Gold najbliżej siebie." +
            "\n" +
            "Dojazd w 12 minut z każdego miejsca w Warszawie." +
            "\n\n" +
            "Kliknij w interesujący Cię salon aby zobaczyć informacje."
          }
          showButton={false}
          align={"text-left"}
          content={"POZNAJ SZCZEGÓŁY"}
          path={""}
        />
        {renderContent()}
      </div>
      <div className="Right-side">
        <div
          className={
            city === "Warszawa" ? "map_photo_warszawa" : "map_photo_poznan"
          }
        >
          {locations.map((loc, index) => (
            <Location
              key={index}
              location={loc.location}
              address={loc.address}
              position_x={loc.position_x}
              position_y={loc.position_y}
              pin_position_x={loc.pin_position_x}
              pin_position_y={loc.pin_position_y}
              isSelected={selectedLocation === index}
              onClick={() => handleLocationClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
