import React, { useState, useEffect, useRef } from "react";
import "./ChosenForYou.scss";
import ItemCardHover from "../../../UI/ItemCardHover/ItemCardHover";
import {
  CustomLeftArrow,
  CustomRightArrow,
} from "../../../UI/Carousel/CustomArrows/CustomArrows";
import { useProduct } from "../../../../hooks/useProduct";
import { IProduct } from "../../../../types/IProduct";

export default function ChosenForYou() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemWidth = 290;
  const gap = 30;
  const totalItemWidth = itemWidth + gap;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [itemsToShow, setItemsToShow] = useState(0);
  const { product } = useProduct();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await product.getAll.execute();
        if (response) {
          const extractedProducts = response.map((item) => item.product);
          setProducts(extractedProducts);
        }
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        setItemsToShow(Math.floor(carouselWidth / totalItemWidth));
        setCurrentIndex(0);
      }
    };

    updateItemsToShow();

    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, [totalItemWidth]);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < products.length - itemsToShow) {
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, products.length - itemsToShow)
      );
    }
  };

  // const showLeftArrow = currentIndex > 0;
  // const showRightArrow = currentIndex < products.length - itemsToShow - 1;
  const showLeftArrow = false;
  const showRightArrow = false;

  return (
    <div className="ChosenForYou-content">
      <div className="heading">
        <h1>WYBRALIŚMY DLA CIEBIE</h1>
      </div>
      <div className="carousel" ref={carouselRef}>
        {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${currentIndex * totalItemWidth}px)`,
          }}
          ref={itemsRef}
        >
          {products.map((product, index) => (
            <ItemCardHover
              key={index}
              product={product}
              content={"ZOBACZ SZCZEGÓŁY"}
            />
          ))}
        </div>
        {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
      </div>
    </div>
  );
}
