import "./FooterIcons.scss";
import { ReactComponent as FacebookIcon } from "../../../../icons/Facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../../icons/Linkedin.svg";
import { ReactComponent as TikTokIcon } from "../../../../icons/TikTok.svg";
import { ReactComponent as InstagramIcon } from "../../../../icons/Instagram.svg";

export default function FooterIcons() {
  return (
    <div className="FooterIconsRow">
      <a href="https://www.tiktok.com/@jubilergwiazd">
        <TikTokIcon />
      </a>
      <a href="https://www.instagram.com/mmgoldpolska/?locale=pl&hl=en">
        <InstagramIcon />
      </a>
      <a href="https://www.facebook.com/bizuteriazbrylantami/?locale=pl_PL">
        <FacebookIcon />
      </a>
      <a href="https://www.linkedin.com/company/mmgold/">
        <LinkedinIcon />
      </a>
    </div>
  );
}
