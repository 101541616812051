import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IProduct } from "../types/IProduct";
import { IStatus } from "../types/IStatus";
import { useStorage } from "./useStorage";

export interface IProductResponse {
  product: IProduct;
  status: IStatus;
}

export const useProduct = () => {
  const { api } = useApi();
  const { auth } = useAuth();
  const { storage } = useStorage();
  const currency = storage.local.get("currency");

  const product = {
    //TO DO currency
    getAll: useAsync(async (): Promise<IProductResponse[]> => {
      const response = await api.get<IProductResponse[]>(
        `/public/product?currency=${currency}`
      );
      return response;
    }),

    get: useAsync(async (id: number): Promise<IProductResponse> => {
      const response = await api.get<IProductResponse>(
        `/public/product/${id}?currency=${currency}`
      );
      return response;
    }),

    search: useAsync(async (phrase: string): Promise<IProductResponse[]> => {
      const response = await api.get<IProductResponse[]>(
        `/public/product/search/${phrase}?currency=${currency}`
      );
      return response;
    }),

    create: useAsync(async (newProduct: IProduct): Promise<IProduct> => {
      auth.validate();
      const response = await api.post<IProduct>(
        "/moderator/product",
        newProduct
      );
      return response;
    }),

    update: useAsync(
      async (id: number, updatedProduct: IProduct): Promise<IProduct> => {
        auth.validate();
        const response = await api.put<IProduct>(
          `/moderator/product/${id}`,
          updatedProduct
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/product/${id}`);
    }),
  };

  return { product };
};
