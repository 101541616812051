import { useParams } from "react-router-dom";
import Page from "../../components/UI/Page/Page";
import Catalog from "../Collections/Catalog/Catalog";
import Sort from "../Collections/Sort/Sort";
import "./Categories.scss";
import OpeningSection from "./OpeningSection/OpeningSection";

export default function Categories() {
  const { category } = useParams();

  return (
    <Page>
      <OpeningSection
        title={category ? category : "PIERŚCIONKI"}
        text={
          "Pierścionki, które symbolizują miłość i wyjątkowe chwile. Wykonane z" +
          "\n" +
          " najwyższej jakości materiałów, urzekają pięknem i subtelnością."
        }
      />
      <Sort />
      <Catalog photo={false} title="" />
    </Page>
  );
}
