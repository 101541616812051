import { useParams } from "react-router-dom";
import InfoSection from "../../components/Pages/Home/OpeningSection/InfoSection";
import Page from "../../components/UI/Page/Page";
import MatchingProducts from "./MatchingProducts/MatchingProducts";
import "./Product.scss";
import ProductDescription from "./ProductDescription/ProductDescription";
import ProductDetails from "./ProductDetails/ProductDetails";
import { useEffect, useState } from "react";
import { useProduct } from "../../hooks/useProduct";
import { IProduct } from "../../types/IProduct";

export default function Product() {
  const { id } = useParams();
  const { product } = useProduct();
  const [actualProduct, setactualProduct] = useState<IProduct>();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProduct = async () => {
      // console.log("POBIERAM produkt:", id);
      try {
        if (id) {
          const response = await product.get.execute(+id);
          if (response) {
            setactualProduct(response.product);
            // console.log("Pobrano produkt:", id);
          }
        }
      } catch (error) {
        console.error(`Błąd podczas pobierania produktu o ID ${id}:`, error);
      }
    };

    fetchProduct();
  }, []);

  return (
    <Page>
      <ProductDetails product={actualProduct} />
      <ProductDescription product={actualProduct} />
      <div className="dlaczego_section">
        <p>DLACZEGO M&M GOLD?</p>
        <InfoSection />
      </div>
      <MatchingProducts />
    </Page>
  );
}
