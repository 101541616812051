import { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { IProduct } from "../../../../types/IProduct";
import { ISuperCategory } from "../../../../types/ISuperCategory";
import { ICategory } from "../../../../types/ICategory";
import { ICollection } from "../../../../types/ICollection";
import { IType } from "../../../../types/IType";
import ProductFormSelect from "./ProductFormSelect";

interface ProductFormProps {
  initialValues: IProduct | null;
  onSave: (data: any) => void;
}

export default function ProductForm({
  onSave,
  initialValues,
}: ProductFormProps) {
  const [form] = Form.useForm();
  const [selectedCategory, setSelectedCategory] = useState<
    ICategory | undefined
  >(undefined);
  const [selectedCollection, setSelectedCollection] = useState<
    ICollection | undefined
  >(undefined);
  const [selectedSuperCategory, setSelectedSuperCategory] = useState<
    ISuperCategory | undefined
  >(undefined);
  const [selectedType, setSelectedType] = useState<IType | undefined>(
    undefined
  );

  const onFinish = (values: IProduct) => {
    const formattedValues = {
      ...values,
      superCategory: { id: selectedSuperCategory?.id },
      category: { id: selectedCategory?.id },
      collection: { id: selectedCollection?.id },
      type: { id: selectedType?.id },
    };
    // console.log(values);
    onSave(formattedValues);
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setSelectedCategory(initialValues.category);
      setSelectedCollection(initialValues.collection);
      setSelectedSuperCategory(initialValues.superCategory);
      setSelectedType(initialValues.type);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Form form={form} name="productForm" onFinish={onFinish} layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Super Kategoria"
            name="superCategory"
            rules={[{ required: true, message: "To pole jest wymagane" }]}
          >
            <ProductFormSelect<ISuperCategory>
              placeholder="Wybierz kategorię"
              value={selectedSuperCategory}
              onChange={setSelectedSuperCategory}
              dataType="supercategory"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Kategoria"
            name="category"
            rules={[{ required: true, message: "To pole jest wymagane" }]}
          >
            <ProductFormSelect<ICategory>
              placeholder="Wybierz kategorię"
              value={selectedCategory}
              onChange={setSelectedCategory}
              dataType="category"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Kolekcja"
            name="collection"
            rules={[{ required: true, message: "To pole jest wymagane" }]}
          >
            <ProductFormSelect<ICollection>
              placeholder="Wybierz kolekcję"
              value={selectedCollection}
              onChange={setSelectedCollection}
              dataType="collection"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Typ"
            name="type"
            rules={[{ required: true, message: "To pole jest wymagane" }]}
          >
            <ProductFormSelect<IType>
              placeholder="Wybierz typ"
              value={selectedType}
              onChange={setSelectedType}
              dataType="type"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Zatwierdź
        </Button>
      </Form.Item>
    </Form>
  );
}
