import { createContext, useReducer } from "react";
import { Children } from "../interfaces/children";
import { useStorage } from "../hooks/useStorage";

type WishlistState = {
  items: number[];
};

type WishlistAction =
  | { type: "ADD_ITEM"; id: number }
  | { type: "REMOVE_ITEM"; id: number };

const WishlistContext = createContext({
  items: [] as number[],
  addItem: (id: number) => {},
  removeItem: (id: number) => {},
});

function updateStorage(
  items: number[],
  storage: { local: { setJSON: (key: string, value: any) => void } }
) {
  storage.local.setJSON("wishlist", items);
}

function wishlistReducer(
  state: WishlistState,
  action: WishlistAction,
  storage: { local: { setJSON: (key: string, value: any) => void } }
) {
  if (action.type === "ADD_ITEM") {
    if (state.items.includes(action.id)) {
      return state;
    }

    const updatedItems = [...state.items, action.id];

    updateStorage(updatedItems, storage);
    console.log("Dodano do wishlist");

    return { ...state, items: updatedItems };
  }

  if (action.type === "REMOVE_ITEM") {
    const updatedItems = state.items.filter((itemId) => itemId !== action.id); // Usunięcie ID

    updateStorage(updatedItems, storage);
    console.log("Usunięto z wishlist");

    return { ...state, items: updatedItems };
  }

  return state;
}

export function WishlistContextProvider({ children }: Children) {
  const { storage } = useStorage();
  const initialWishlistState: WishlistState = {
    items: storage.local.getJSON("wishlist") || [],
  };

  const wrappedReducer = (state: WishlistState, action: WishlistAction) =>
    wishlistReducer(state, action, storage);

  const [wishlist, dispatchWishlistAction] = useReducer(
    wrappedReducer,
    initialWishlistState
  );

  function addItem(id: number) {
    dispatchWishlistAction({ type: "ADD_ITEM", id });
  }

  function removeItem(id: number) {
    dispatchWishlistAction({ type: "REMOVE_ITEM", id });
  }

  const wishlistContext = {
    items: wishlist.items,
    addItem,
    removeItem,
  };

  console.log(wishlistContext);

  return (
    <WishlistContext.Provider value={wishlistContext}>
      {children}
    </WishlistContext.Provider>
  );
}

export default WishlistContext;
