import { useEffect, useState } from "react";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import UserProfileEdit from "../UserProfileEdit/UserProfileEdit";
import "./UserProfile.scss";
import { useAuth } from "../../../../hooks/useAuth";
import { IUser } from "../../../../types/IUser";
import { useStorage } from "../../../../hooks/useStorage";

export default function UserProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const { auth } = useAuth();
  const { storage } = useStorage();
  const user = storage.session.getJSON<IUser | null>("user");

  if (isEditing) {
    return <UserProfileEdit user={user} onCancel={() => setIsEditing(false)} />;
  }

  return (
    <div className="user-profile">
      <div className="title">
        <h2 className="titleh2">Moje dane</h2>
      </div>

      <div className="account__data">
        <div className="account__data-group">
          <h3>Imię i nazwisko:</h3>
          <p>{user?.contactData?.name + " " + user?.contactData?.surname}</p>
        </div>

        <div className="account__data-group">
          <h3>Adres (ulica i numer domu/lokalu):</h3>
          <p>
            {user?.contactData?.streetName +
              " " +
              user?.contactData?.streetNumber}
          </p>
        </div>

        <div className="account__data-group">
          <h3>Miejscowość:</h3>
          <p>{user?.contactData?.city}</p>
        </div>

        <div className="account__data-group">
          <h3>E-mail:</h3>
          <p>{user?.email}</p>
        </div>

        <div className="account__data-group">
          <h3>Kod pocztowy:</h3>
          <p>{user?.contactData?.postalCode}</p>
        </div>

        <div className="account__data-group">
          <h3>Kraj:</h3>
          <p>{user?.contactData?.country}</p>
        </div>
      </div>
      <div className="buttons">
        <AdjustableButton
          text="Edytuj dane"
          onClick={() => setIsEditing(true)}
          backgroundColor="#f0f0f0"
          textColor="#333"
          borderColor="#ccc"
          hoverBackgroundColor="#e0e0e0"
          hoverTextColor="#000"
        />
        <AdjustableButton text={"Wyloguj się"} onClick={auth.signout} />
      </div>
    </div>
  );
}
