import "./PictureHeader.scss";

type PictureHeaderProps = {
  imgSrc: string;
  heading: string;
  description: string;
};

export default function PictureHeader({
  imgSrc,
  heading,
  description,
}: PictureHeaderProps) {
  return (
    <div className="new">
      <img src={imgSrc} alt="" />
      <div className="textSection">
        <div className="text">
          <h1>{heading}</h1>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
