import { useState, useEffect } from "react";
import "./NavDrawer.scss";
import { NavDrawerTabs } from "./NavDrawerTabs/NavDrawerTabs";

interface DrawerProps {
  open: boolean;
  data: string;
}

interface Option {
  key: string;
  label: string;
  path: string;
}

const products: Option[] = [
  {
    key: "1",
    label: "NASZYJNIKI",
    path: "/kategorie/naszyjniki",
  },
  {
    key: "2",
    label: "BRANSOLETKI",
    path: "/kategorie/bransoletki",
  },
  {
    key: "3",
    label: "PIERŚCIONKI",
    path: "/kategorie/pierscionki",
  },
  {
    key: "4",
    label: "KOLCZYKI",
    path: "/kategorie/kolczyki",
  },
  {
    key: "5",
    label: "ZEGARKI",
    path: "/kategorie/zegarki",
  },
  {
    key: "6",
    label: "BROSZKI",
    path: "/kategorie/broszki",
  },
  {
    key: "7",
    label: "BRYLANTY",
    path: "/kategorie/brylanty",
  },
];

const collections: Option[] = [
  {
    key: "1",
    label: "HIGH JEWELS",
    path: "/kolekcje",
  },
  {
    key: "2",
    label: "INVISIBLE",
    path: "/kolekcje",
  },
  {
    key: "3",
    label: "ZARĘCZYNY",
    path: "/kolekcje",
  },
  {
    key: "4",
    label: "ANTYCZNE",
    path: "/kolekcje",
  },
];

const forHim: Option[] = [
  {
    key: "1",
    label: "BRANSOLETKI",
    path: "/kategorie/bransoletki",
  },
  {
    key: "2",
    label: "SYGNETY",
    path: "/kategorie/sygnety",
  },
  {
    key: "3",
    label: "ŁAŃCUSZKI",
    path: "/kategorie/lancuszki",
  },
  {
    key: "4",
    label: "BRELOKI",
    path: "/kategorie/breloki",
  },
];

export default function NavDrawer(props: DrawerProps) {
  const { open, data } = props;

  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (data === "products") {
      setOptions(products);
    } else if (data === "for-him") {
      setOptions(forHim);
    } else {
      setOptions(collections);
    }
  }, [data, open]);

  return (
    <div className={`dropdown-content ${open ? "show-content" : ""}`}>
      <NavDrawerTabs options={options} />
    </div>
  );
}
