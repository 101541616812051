import React from "react";
import "./OpeningSection.scss";
import Text from "../../../components/UI/Text/Text";

interface DrawerProps {
  title: string;
  text: string;
}

export default function OpeningSection({ title, text }: DrawerProps) {
  return (
    <div className="categoriesOpening">
      <div className="image-container">
        <img src="../images/categories.png" alt="Contact Details" />
      </div>
      <div className="text-container">
        <Text
          title={title}
          text={text}
          showButton={false}
          align="text-left"
          content=""
          path=""
        />
      </div>
    </div>
  );
}
