import ChosenForYou from "../../components/Pages/Checkout/ChosenForYou/ChosenForYou";
import ShippingDetails from "../../components/Pages/Checkout/ShippingDetails/ShippingDetails";
import YourOrder from "../../components/Pages/Checkout/YourOrder/YourOrder";
import Page from "../../components/UI/Page/Page";
import AdjustableButton from "../../components/UI/AdjustableButton/AdjustableButton";
import PaymentMethods from "../../components/Pages/Cart/PaymentMethods/PaymentMethods";
import "./CartCheckout.scss";
import { IOrder } from "../../types/IOrder";
import { useContext, useEffect, useState } from "react";
import { IProduct } from "../../types/IProduct";
import { useOrder } from "../../hooks/useOrder";
import { useProduct } from "../../hooks/useProduct";
import CartContext from "../../context/CartContext";
import { IAttribute } from "../../types/IAttribute";

export default function CartCheckout() {
  const [orderData, setOrderData] = useState<IOrder>();
  const [productsData, setProductsData] = useState<IProduct[]>();
  const [selectedDeliveryMessage, setSelectedDeliveryMessage] = useState<string | null>(null);
  const [selectedPaymentMessage, setSelectedPaymentMessage] = useState<string | null>(null);
  const cartCtx = useContext(CartContext);
  const { product } = useProduct();
  const cart = cartCtx.items;
  const [products, setProducts] = useState<(IProduct & { quantity: number })[]>(
    []
  );
  const { order } = useOrder();
  const calculateTotal = (
    products: (IProduct & { quantity: number })[]
  ): number => {
    return products.reduce((total, product) => {
      const price = product.attribute?.price || 0;
      return total + price * product.quantity;
    }, 0);
  };

  const total = calculateTotal(products);

  const handleOrderData = (data: IOrder) => {
    const updatedOrder: IOrder = {
      ...data,
      products: productsData || [],
      price: total,
    };

    console.log(updatedOrder);
    onFinish(updatedOrder);
  };

  const onFinish = async (orderRequest: IOrder) => {
    try {
      const response = await order.create.execute(orderRequest);

      const paymentLink = response?.order?.payment?.link;
      if (paymentLink) {
        window.location.href = paymentLink;
      }
    } catch (err) {
      console.error("Wystąpił błąd:", err);
    }
  };

  useEffect(() => {
    const fetchCartProducts = async () => {
      const fetchedProducts: (IProduct & {
        quantity: number;
      })[] = [];
      const fetchedProducts2: IProduct[] = [];

      for (const item of cart) {
        try {
          const response = await product.get.execute(item.id);
          if (response) {
            fetchedProducts.push({
              ...response.product,
              attribute: {
                ...response.product.attribute,
                colors: [item.color],
              } as IAttribute,
              quantity: item.quantity,
            });

            for (let i = 0; i < item.quantity; i++) {
              fetchedProducts2.push({
                ...response.product,
                attribute: {
                  ...response.product.attribute,
                  colors: [item.color],
                } as IAttribute,
              });
            }
          }
        } catch (error) {
          console.error(
            `Błąd podczas pobierania produktu o ID ${item.id}:`,
            error
          );
        }
      }

      setProducts(fetchedProducts);
      setProductsData(fetchedProducts2);
    };

    fetchCartProducts();
  }, []);

  return (
    <Page>
      <div className="heading">
        <h1 className="CheckoutHeading">PODSUMOWANIE ZAMÓWIENIA</h1>
        <hr className="CheckoutHR" />
        <AdjustableButton
          text={"WRÓĆ DO KOSZYKA"}
          href={"/koszyk"}
          textColor="white"
          borderColor="white"
          backgroundColor="black"
          hoverBackgroundColor="white"
          hoverTextColor="black"
          hoverBorderColor="black"
        />
      </div>
      <div className="CartCheckout">
        <ShippingDetails setSelectedDeliveryText={setSelectedDeliveryMessage} setSelectedPaymentText={setSelectedPaymentMessage} onSave={handleOrderData} />
        <YourOrder selectedDeliveryText={selectedDeliveryMessage} selectedPaymentText={selectedPaymentMessage} products={products} total={total} />
      </div>
      <ChosenForYou />
      <PaymentMethods />
    </Page>
  );
}
