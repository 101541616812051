import React from "react";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import "./Sizeer.scss";

const Sizeer = () => {
  return (
    <div className="sizeer">
      <div className="content">
        <div className="text-section">
          <h1>ZAMÓW BEZPŁATNY SIZEER</h1>
          <p>
            Nie wiesz jaki rozmiar produktu zamówić?
            <br></br>Z chęcią wyślemy Ci bezpłatny sizeer.
          </p>
          <div className="button">
            <AdjustableButton
              text="ZOBACZ TERAZ"
              backgroundColor="transparent"
              textColor="white"
              borderColor="white"
              hoverBackgroundColor="white"
              hoverTextColor="black"
              hoverBorderColor="black"
              href="/rozmiary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sizeer;
