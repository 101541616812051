import "./PrivacyHeader.scss";
import Text from "../../../UI/Text/Text";

export default function PrivacyHeader() {
  return (
    <div className="PrivacyHeader">
      <Text
        title={"POLITYKA PRYWATNOŚCI"}
        text={""}
        showButton={false}
        align={"text-center"}
        content={""}
        path={""}
      />
    </div>
  );
}
