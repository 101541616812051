import React from "react";
import "./PasswordChange.scss";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import { ReactComponent as Padlock } from "../../../../icons/padlock.svg";

export default function PasswordChange() {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="password">
      <h2 className="password__title">Zmień hasło</h2>

      <form onSubmit={handleSubmit} className="password__form">
        <div className="password__group">
          <label htmlFor="current-password" className="password__label">
            Aktualne hasło
          </label>
          <div className="password__input-container">
            <span className="password__icon">
              <Padlock />
            </span>
            <input
              type="password"
              id="current-password"
              className="password__input"
            />
          </div>
        </div>

        <div className="password__group">
          <label htmlFor="new-password" className="password__label">
            Nowe hasło
          </label>
          <div className="password__input-container">
            <span className="password__icon">
              <Padlock />
            </span>
            <input
              type="password"
              id="new-password"
              className="password__input"
            />
          </div>
        </div>

        <div className="password__group">
          <label htmlFor="confirm-password" className="password__label">
            Potwierdź nowe hasło
          </label>
          <div className="password__input-container">
            <span className="password__icon">
              <Padlock />
            </span>
            <input
              type="password"
              id="confirm-password"
              className="password__input"
            />
          </div>
        </div>
        <div className="password-change__actions">
          <AdjustableButton text={"ZMIEŃ HASŁO"} />
        </div>
      </form>
    </div>
  );
}
