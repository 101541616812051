import React, { Fragment } from "react";
import Main from "../Main/Main";
import { Children } from "../../../interfaces/children";
import FloatingButton from "../FloatingButton/FloatingButton";
import Navbar from "../Navbar/Navbar";
import Footbar from "../Footbar/Footbar";

export default function Page({ children }: Children) {
  return (
    <Fragment>
      <Navbar />
      <Main>{children}</Main>
      <Footbar />
      <FloatingButton />
    </Fragment>
  );
}
