import "./Departments.scss";
import DepartmentItem from "./DepartmentItem/DepartmentItem";
import IconTextGradient from "../../../UI/IconTextGradient/IconTextGradient";

const Departments = () => {
  return (
    <div className="departments">
      <div className="departments-title">NASZE DEPARTAMENTY</div>
      <div className="departments-container">
        <div className="departments-column">
          <DepartmentItem
            title="ZAMÓWIENIA INTERNETOWE"
            phone="+48 787 787 777"
          />
          <DepartmentItem
            title="DOM AUKCYJNY ANORAMA"
            phone="+48 787 787 777"
          />
          <DepartmentItem
            title="SKUP BRYLANTÓW, BIŻUTERII I ZŁOTA"
            phone="+48 787 787 777"
          />
        </div>
        <div className="departments-column">
          <DepartmentItem
            title="WYCENY MIĘDZYNARODOWEGO EKSPERTA"
            phone="+48 787 787 777"
          />
          <DepartmentItem
            title="SKUP ZEGARKÓW"
            phone="+48 787 787 777"
          />
          <DepartmentItem
            title="SPRZEDAŻ HURTOWA BRYLANTÓW DLA FIRM"
            phone="+48 787 787 777"
          />
        </div>
        <div className="customer-service">
          <div className="customer-service-title">BIURO OBSŁUGI KLIENTA</div>
          <div className="customer-service-contact">
            <IconTextGradient
              icon="phone"
              text="+48 XXX XXX XXX"
              hrefText="tel:+48XXXXXXXXX"
            />
            <IconTextGradient 
              icon="email" 
              text="E-MAIL" 
              hrefText="mailto:test@siegeventure.com/contact"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
