import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import footerData from "../../../../utils/footerData";
import { ReactComponent as JubilerGwiazd } from "../../../../icons/jubilerGwiazd.svg";
import "./FooterContent.scss";

export default function FooterContent() {
  const isMobile = useMediaQuery("(max-width:800px)");
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const accordionSections = ["O NAS", "NASZE SALONY", "INFORMACJE"];

  return (
    <>
      <footer className="footer">
        {footerData.map((section, index) => (
          <div key={index} className="footer-section">
            {isMobile && accordionSections.includes(section.title) ? (
              <Accordion
                expanded={expandedIndex === index}
                onChange={() => handleAccordionChange(index)}
                className="accordion"
                style={{ backgroundColor: "black" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  className="accordionHeader"
                  style={{ color: "black" }}
                >
                  <h4>{section.title}</h4>
                </AccordionSummary>
                <AccordionDetails
                  className="accordionAnswer"
                  style={{ backgroundColor: "black" }}
                >
                  <ul>
                    {section.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        {link.icon && <img src={link.icon} alt="" />}
                        <a href={link.href}>{link.text}</a>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <h4>{section.title}</h4>
                <ul>
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      {link.icon && <img src={link.icon} alt="" />}
                      <a href={link.href}>{link.text}</a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ))}
      </footer>
      <JubilerGwiazd />
    </>
  );
}
