import "./BankAccount.scss";
import CollapsibleItem from "../../../UI/CollapsibleItem/CollapsibleItem";
import IconTextGradient from "../../../UI/IconTextGradient/IconTextGradient";

const BankAccount = () => {
  return (
    <div className="bank-account">
      <div className="contact-info">
        <div className="contact-image-bg"></div>
        <img
          src="../images/Contact-BankAccount.jpg"
          alt="M&M Gold"
          className="contact-image"
        />
        <div className="contact-description">
          <h1>M&M GOLD</h1>
          <p>al. Wincentego Witosa 31/10, 00-710 Warszawa</p>
          <p>NIP: 5213529744</p>
          <p>REGON: 145841649</p>
          <IconTextGradient
            icon="email"
            text="WYŚLIJ E-MAIL"
            gradientDirection="to left"
            hrefText="mailto:test@siegeventure.com/contact" // nie jestem pewien czy to prawidłowy mail
          />
        </div>
      </div>
      <CollapsibleItem
        title="NASZ NUMER KONTA W PLN"
        content={[
          <strong>mBiznes EUR</strong>,
          "PL 91 1140 2004 0000 3912 0480 3856",
          "BIC/SWIFT: BREXPLPWM",
        ]}
      />
      <CollapsibleItem
        title="NASZ NUMER KONTA W EUR"
        content={[
          <strong>mBiznes EUR</strong>,
          "PL 91 1140 2004 0000 3912 0480 3856",
          "BIC/SWIFT: BREXPLPWM",
        ]}
      />
    </div>
  );
};

export default BankAccount;
