import { Descriptions, DescriptionsProps, Modal, Table } from "antd";
import { IProduct } from "../../../../../types/IProduct";

type Props = {
  product: IProduct | null | undefined;
};

export default function AttributesDetails({ product }: Props) {
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Nazwa",
      children: product?.attribute?.name || "-",
    },
    // {
    //   key: "1",
    //   label: "Super kategoria",
    //   children: product?.superCategory?.name || "-",
    // },
    // {
    //   key: "1",
    //   label: "Kategoria",
    //   children: product?.category?.name || "-",
    // },
    // {
    //   key: "1",
    //   label: "Kolekcja",
    //   children: product?.collection?.name || "-",
    // },
    // {
    //   key: "1",
    //   label: "Typ",
    //   children: product?.type?.name || "-",
    // },
    {
      key: "2",
      label: "Kolory",
      children:
        product?.attribute?.colors?.map((color) => color.name).join(" / ") ||
        "-",
    },
    {
      key: "3",
      label: "Cena",
      children: `${product?.attribute?.price?.toFixed(2)}` || "-",
    },
    {
      key: "4",
      label: "Metal",
      children: product?.attribute?.metal || "-",
    },
    {
      key: "5",
      label: "Próba złota",
      children: product?.attribute?.goldTest || "-",
      // children: product?.attribute?.goldTest != null ? `${product.attribute.goldTest} ct` : "-",
    },
    {
      key: "6",
      label: "Kamień",
      children: product?.attribute?.stone || "-",
    },
    {
      key: "7",
      label: "Waga kamienia",
      children:
        product?.attribute?.stoneWeight != null
          ? `${product.attribute.stoneWeight} ct`
          : "-",
    },
    {
      key: "8",
      label: "Zapięcie",
      children: product?.attribute?.clasp || "-",
    },
    {
      key: "9",
      label: "Numer referencyjny",
      children: product?.attribute?.referenceNumber || "-",
    },
    {
      key: "10",
      label: "Średnica",
      children:
        product?.attribute?.diameter != null
          ? `${product.attribute.diameter} mm`
          : "-",
    },
    {
      key: "11",
      label: "Mechanizm",
      children: product?.attribute?.mechanism || "-",
    },
    {
      key: "12",
      label: "Kolor tarczy",
      children: product?.attribute?.dialColor || "-",
    },
    {
      key: "13",
      label: "Stan produktu",
      children: product?.attribute?.productCondition || "-",
    },
    {
      key: "14",
      label: "Zawartość opakowania",
      children: product?.attribute?.included || "-",
    },
    {
      key: "15",
      label: "Data produkcji",
      children: product?.attribute?.productionDate || "-",
    },
    {
      key: "16",
      label: "Czystość kamienia",
      children: product?.attribute?.clarity || "-",
    },
    {
      key: "17",
      label: "Typ szlifu",
      children: product?.attribute?.cutType || "-",
    },
    {
      key: "18",
      label: "Polecany",
      children: product?.attribute?.isRecommended ? "Tak" : "Nie",
    },
    {
      key: "19",
      label: "Opis",
      children: (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {product?.attribute?.description || "-"}
        </div>
      ),
    },
  ];

  return (
    <Descriptions title="Atrybuty" layout="horizontal" bordered items={items} />
  );
}
