import React, { useState, useEffect, useRef } from "react";
import "./FollowUs.scss";
import Text from "../../../UI/Text/Text";
import { FaInstagram } from "react-icons/fa";
import {
  CustomLeftArrow,
  CustomRightArrow,
} from "../../../UI/Carousel/CustomArrows/CustomArrows";

const images = [
  { imageSrc: "/images/FollowUs1.png" },
  { imageSrc: "/images/FollowUs2.png" },
  { imageSrc: "/images/FollowUs3.png" },
  { imageSrc: "/images/FollowUs4.png" },
  { imageSrc: "/images/FollowUs5.png" },
  { imageSrc: "/images/FollowUs6.png" },
];

export default function FollowUs() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemWidth] = useState(192);
  const gap = 15;
  const totalItemWidth = itemWidth + gap;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [itemsToShow, setItemsToShow] = useState(0);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        const newItemsToShow = Math.floor(carouselWidth / totalItemWidth);
        setItemsToShow(newItemsToShow);
        setCurrentIndex(0);
      }
    };

    updateItemsToShow();

    window.addEventListener("resize", updateItemsToShow);

    return () => {
      window.removeEventListener("resize", updateItemsToShow);
    };
  }, [totalItemWidth]);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, images.length - itemsToShow)
    );
  };

  const handleFollowUsClick = () => {
    window.location.href = "https://www.instagram.com/mmgoldpoland/";
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < images.length - itemsToShow - 1;

  return (
    <div className="follow-content">
      <Text
        title={"ZAOBSERWUJ NAS "}
        text={
          "Chcesz być na bieżąco z nowościami i okazjami? Zaobserwuj nas na Instagramie."
        }
        showButton={false}
        align={"text-center"}
        content={""}
        path={""}
      />
      <button className="follow-button" onClick={handleFollowUsClick}>
        <FaInstagram /> Follow Us
      </button>
      <div className="follow-carousel" ref={carouselRef}>
        {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${currentIndex * (itemWidth + gap)}px)`,
          }}
          ref={itemsRef}
        >
          {images.map((image, index) => (
            <div key={index} className="follow-item">
              <img
                src={image.imageSrc}
                alt={`Follow us ${index + 1}`}
                style={{ width: itemWidth }}
              />
            </div>
          ))}
        </div>
        {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
        <p>@mmgoldpoland</p>
      </div>
    </div>
  );
}
