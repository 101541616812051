import OrderPicture from "../../components/Pages/OrderPage/OrderPicture/OrderPicture";
import Page from "../../components/UI/Page/Page";
import CategoryCard from "../../components/UI/CategoryCard/CategoryCard";
import "./Order.scss";
import Catalog from "../Collections/Catalog/Catalog";
export default function Order() {
  return (
    <Page>
      <OrderPicture />
      <div className="Cards">
        <CategoryCard
          variant={"narrow"}
          imgPath={"./images/Category-Kolczyki.jpeg"}
          title={""}
          buttonText={"ZAMÓW PRODUKT"}
          buttonLink={""}
        />
        <CategoryCard
          variant={"narrow"}
          imgPath={"./images/Category-Pierscionki.jpeg"}
          title={""}
          buttonText={"WYCEŃ SWOJĄ BIUŻUTERIĘ"}
          buttonLink={"#"}
        />
      </div>
      <Catalog photo={false} title="" />
    </Page>
  );
}
