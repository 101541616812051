import React from "react";
import "./DiamondComparison.scss";
import Text from "../../../UI/Text/Text";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";

export default function DiamondComparison() {
  return (
    <div className="diamond-content">
      <div className="diamond-image">
        <img src="./images/DiamondComparison.jpeg" alt="Diamond" />
      </div>
      <div className="diamond-text">
        <Text
          title={"diament a brylant"}
          text={
            "Diament i brylant to terminy często używane wymiennie, ale różnią się one znaczeniem i zastosowaniem w świecie biżuterii. Diament to naturalnie występujący minerał, znany ze swojej niesamowitej twardości i zdolności do załamywania światła, co nadaje mu wyjątkowy blask. Diamenty są jednym z najcenniejszych i najbardziej pożądanych klejnotów na świecie. "
          }
          showButton={false}
          align={"text-left"}
          content={""}
          path={""}
        />
        <Text
          title={""}
          text={
            "Brylant natomiast to diament, który został precyzyjnie oszlifowany według określonego kształtu, zazwyczaj jest to szlif brylantowy. Ten szlif jest zaprojektowany tak, aby wydobyć jak najwięcej blasku i ognia z diamentu, czyli jego zdolności do rozszczepiania światła na różne barwy. Brylanty są najbardziej popularne w świecie biżuterii, zwłaszcza jako kamienie w pierścionkach zaręczynowych i innych prestiżowych ozdobach."
          }
          showButton={false}
          align={"text-left"}
          content={""}
          path={""}
        />
        <AdjustableButton
          text={"Poznaj Ofertę"}
          href={"/kategorie/diamenty"}
          textColor="black"
          borderColor="black"
          backgroundColor="white"
          hoverBackgroundColor="black"
          hoverBorderColor="white"
          hoverTextColor="white"
        />
      </div>
    </div>
  );
}
