import React from "react";
import "./AdjustableButton.scss";
import { AdjustableButtonProps } from "../../../interfaces/adjustableButtonProps";

export default function AdjustableButton({
  text,
  backgroundColor,
  textColor,
  borderColor,
  hoverBackgroundColor,
  hoverTextColor,
  hoverBorderColor,
  href,
  onClick,
}: AdjustableButtonProps) {
  const styles = {
    "--background-color": backgroundColor,
    "--text-color": textColor,
    "--border-color": borderColor,
    "--hover-background-color": hoverBackgroundColor,
    "--hover-text-color": hoverTextColor,
    "--hover-border-color": hoverBorderColor,
    cursor: "pointer",
  } as React.CSSProperties;

  return href ? (
    <a href={href} className="adjustable-button" style={styles}>
      {text}
    </a>
  ) : (
    <button onClick={onClick} className="adjustable-button" style={styles}>
      {text}
    </button>
  );
}
