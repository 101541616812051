import Page from "../../components/UI/Page/Page";
import OpeningSection from "../Categories/OpeningSection/OpeningSection";
import Catalog from "../Collections/Catalog/Catalog";
import "./AllProducts.scss";
import AllProductsCategories from "./AllProductsCategories/AllProductsCategories";
import Sort from "../Collections/Sort/Sort";

export default function AllProducts() {
  return (
    <Page>
      <OpeningSection
        title="Produkty"
        text={
          "Odkryj produkty, które zachwycają najbardziej wymagających klientów." +
          "\n" +
          " Wśród grona osób, które zakochały się w naszej biżuterii jest wiele gwiazd."
        }
      />
      <Sort />
      <p
        style={{
          color: "white",
          marginLeft: "90px",
          marginTop: "45px",
          marginBottom: "-45px",
          fontSize: "20px",
        }}
      >
        KATEGORIE
      </p>
      <AllProductsCategories />
      <p
        style={{
          color: "white",
          marginLeft: "90px",
          marginTop: "-45px",
          marginBottom: "-45px",
          fontSize: "20px",
        }}
      >
        PRODUKTY
      </p>
      <Catalog photo={false} title="" />
    </Page>
  );
}
