import { Carousel, Dropdown, notification, Typography } from "antd";
import "./ProductDetails.scss";
import { ReactComponent as HeartIcon } from "../../../icons/heart.svg";
import { ReactComponent as PhoneIcon } from "../../../icons/phone.svg";
import { ReactComponent as CallendarIcon } from "../../../icons/callendar.svg";
import { ReactComponent as BellIcon } from "../../../icons/bell.svg";
import AdjustableButton from "../../../components/UI/AdjustableButton/AdjustableButton";
import CollapsibleItem from "../../../components/UI/CollapsibleItem/CollapsibleItem";
import { IProduct } from "../../../types/IProduct";
import { useStorage } from "../../../hooks/useStorage";
import { useEffect, useState } from "react";
import { useContext } from "react";
import CartContext from "../../../context/CartContext";
import { DownOutlined } from "@ant-design/icons";
import { IColor } from "../../../types/IColor";
import { ISize } from "../../../types/ISize";
const { Text } = Typography;

export default function ProductDetails(props: {
  product: IProduct | undefined;
}) {
  const { product } = props;
  const { storage } = useStorage();
  const [heartChecked, setHeartChecked] = useState(false);
  const cartCtx = useContext(CartContext);
  const currency = storage.local.get("currency");
  const [openColor, setOpenColor] = useState(false);
  const [openSize, setOpenSize] = useState(false);
  const [selectedColor, setSelectedColor] = useState<IColor>();
  const colorItems = product?.attribute?.colors.map((color) => ({
    key: color.id.toString(),
    label: color.name,
  }));
  const [selectedSize, setSelectedSize] = useState<ISize>();
  const sizeItems = product?.attribute?.sizes.map((size) => ({
    key: size.id.toString(),
    label: size.value,
  }));

  function handleAddToCart() {
    if (product) {
      if (selectedColor && selectedSize) {
        cartCtx.addItem(product.id, selectedColor, selectedSize);
      } else {
        cartCtx.addItem(
          product.id,
          product.attribute.colors[0],
          product.attribute.sizes[0]
        );
      }

      notification.success({
        message: "Koszyk",
        description: "Produkt został dodany do koszyka.",
        duration: 2,
      });
    }
  }

  const handleOpenChange = (key: string) => {
    return (nextOpen: boolean, info: { source: string }) => {
      if (info.source === "trigger" || nextOpen) {
        switch (key) {
          case "color":
            setOpenColor(nextOpen);
            break;
          case "size":
            setOpenSize(nextOpen);
            break;
          default:
            break;
        }
      }
    };
  };

  const handleColorClick = ({ key }: { key: string }) => {
    const selected = product?.attribute.colors.find(
      (color) => color.id.toString() === key
    );
    if (selected) {
      setSelectedColor(selected);
      console.log("Selected color:", selected);
    }
    setOpenColor(false);
  };

  const handleSizeClick = ({ key }: { key: string }) => {
    const selected = product?.attribute.sizes.find(
      (size) => size.id?.toString() === key
    );
    if (selected) {
      setSelectedSize(selected);
      console.log("Selected size:", selected);
    }
    setOpenSize(false);
  };

  const toggleHeart = () => {
    const wishlist = storage.local.getJSON<number[]>("wishlist") || [];

    if (heartChecked) {
      const updatedWishlist = wishlist.filter((id) => id !== product?.id);
      storage.local.setJSON("wishlist", updatedWishlist);
    } else {
      product?.id !== undefined && wishlist.push(product?.id);
      storage.local.setJSON("wishlist", wishlist);
    }

    setHeartChecked(!heartChecked);
  };

  useEffect(() => {
    const wishlist = storage.local.getJSON<number[]>("wishlist") || [];
    if (wishlist.includes(product?.id ?? -1)) {
      setHeartChecked(true);
    }

    setSelectedColor(product?.attribute.colors[0]);
    setSelectedSize(product?.attribute.sizes[0]);
  }, [product]);

  return (
    <>
      <div className="ProductDetails">
        <div className="CarouselContainer">
          <Carousel
            arrows
            dots={false}
            infinite={false}
            slidesToShow={2}
            slidesToScroll={1}
            className="productCarousel"
          >
            {product?.attribute?.pictures
              ?.filter((image) => image.color?.id === selectedColor?.id)
              .map((image) => (
                <img
                  key={image.id}
                  src={`data:image/png;base64,${image.directory}`}
                  className="sliderItem"
                  style={{ width: "50%" }}
                />
              ))}
          </Carousel>
        </div>
        <div className="CarouselContainerMobile">
          <Carousel
            arrows
            dots={false}
            infinite={false}
            slidesToShow={1}
            slidesToScroll={1}
            className="productCarousel"
          >
            {product?.attribute?.pictures
              ?.filter((image) => image.color?.id === selectedColor?.id)
              .map((image) => (
                <img
                  key={image.id}
                  src={`data:image/png;base64,${image.directory}`}
                  className="sliderItem"
                  style={{ width: "50%" }}
                />
              ))}
          </Carousel>
        </div>
        <div className="DetailsContainer">
          <h2>{product?.attribute?.name}</h2>
          <p className="opis">{product?.attribute?.description}</p>
          <p className="productColorText">
            {product?.attribute?.colors
              ? product.attribute.colors.map((color) => color.name).join(" / ")
              : ""}
          </p>

          <div className="sizeAndColor">
            {product && product.attribute.colors.length > 1 && (
              <Dropdown
                menu={{ items: colorItems, onClick: handleColorClick }}
                trigger={["click"]}
                overlayClassName="dropdownProduct"
                placement="bottomLeft"
                open={openColor}
                onOpenChange={handleOpenChange("color")}
              >
                <Text style={{ cursor: "pointer" }}>
                  {selectedColor?.name}
                  <DownOutlined
                    className={openColor ? "sortDownOpened" : "sortDown"}
                  />
                </Text>
              </Dropdown>
            )}
            {product && product.attribute.sizes.length > 1 && (
              <Dropdown
                menu={{ items: sizeItems, onClick: handleSizeClick }}
                trigger={["click"]}
                overlayClassName="dropdownProduct"
                placement="bottomLeft"
                open={openSize}
                onOpenChange={handleOpenChange("size")}
              >
                <Text style={{ cursor: "pointer" }}>
                  {selectedSize?.value + " " + selectedSize?.unit}
                  <DownOutlined
                    className={openSize ? "sortDownOpened" : "sortDown"}
                  />
                </Text>
              </Dropdown>
            )}
          </div>

          <p className="cena">
            {product?.attribute?.price !== undefined
              ? new Intl.NumberFormat("pl-PL").format(+product.attribute.price)
              : ""}{" "}
            {currency}
          </p>
          <div className="buttons">
            <AdjustableButton
              text={"DODAJ DO KOSZYKA"}
              href={""}
              onClick={handleAddToCart}
            />
            <button
              onClick={toggleHeart}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              <HeartIcon
                className="heartIcon"
                fill={heartChecked ? "white" : "transparent"}
              />
            </button>
          </div>
          <div className="collapsable">
            <CollapsibleItem
              title="SZCZEGÓŁY"
              content={[
                "Każda kreacja MMGOLD jest wyjątkowa. W rezultacie rozmiar, masa w karatach i jakość kamienia mogą się nieznacznie różnić w zależności od kamienia. Aby uzyskać szczegółowe informacje, skontaktuj się z nami lub zarezerwuj wizytę w sklepie.",
              ]}
              plus
            />
            <CollapsibleItem
              title="ROZMIAR I DOPASOWANIE"
              content={[
                "Sprawdź przygotowaną przez Nas tabele rozmiarów, aby pomóc Ci dokładnie określić rozmiar. Prosimy o zapoznanie się z naszą tabelą pełnowymiarową.",
              ]}
              plus
            />
            <CollapsibleItem
              title="WYSYŁKA I ZWROT"
              content={[
                "Otrzymaj bezpłatną zabezpieczoną wysyłkę i pakowanie prezentów dla wszystkich zamówień MMGOLD. Możesz zwrócić lub wymienić swoje dzieło MMGOLD w ciągu 30 dni.",
              ]}
              plus
            />
          </div>
        </div>
      </div>
      <div className="actionsRow">
        <div className="actionItem">
          <PhoneIcon stroke="white" />
          ZAMÓW PRZEZ TELEFON
        </div>
        <div className="divider" />
        <div className="actionItem">
          <CallendarIcon />
          UMÓW WIZYTĘ W SALONIE
        </div>
        <div className="divider" />
        <div className="actionItem">
          <BellIcon />
          SKONTAKTUJ SIĘ Z EKSPERTEM
        </div>
      </div>
    </>
  );
}
