import { FloatButton } from "antd";
import "./FloatingButton.scss";
export default function FloatingButton() {
  return (
    <div className="buttons">
      <FloatButton
        href="/kontakt"
        className="floating"
        icon={<img src="/images/FloatingButton.png" alt="Zadzwoń" />}
      />
    </div>
  );
}
