import React from "react";
import Page from "../../components/UI/Page/Page";
import AboutDiamondsHeading from "../../components/Pages/AboutDiamonds/AboutDiamondsHeading/AboutDiamondsHeading";
import Brylanty from "../../components/Pages/AboutDiamonds/Brylanty/Brylanty";
import DiamondComparison from "../../components/Pages/AboutDiamonds/DiamondComparison/DiamondComparison";
import DiamondFeatures from "../../components/Pages/AboutDiamonds/DiamondFeatures/DiamondFeatures";
import DiamondLegality from "../../components/Pages/AboutDiamonds/DiamondsLegality/DiamondsLegality";

export default function AboutDiamonds() {
  return (
    <Page>
      <AboutDiamondsHeading />
      <DiamondComparison />
      <DiamondFeatures />
      <DiamondLegality />
      <Brylanty />
    </Page>
  );
}
