import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";
import { IColor } from "../types/IColor";

export interface IColorsResponse {
  color: IColor;
  status: IStatus;
}

export const useColor = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const color = {
    getAll: useAsync(async (): Promise<IColorsResponse[]> => {
      const response = await api.get<IColorsResponse[]>("/public/color");
      return response;
    }),

    get: useAsync(async (id: number): Promise<IColor> => {
      const response = await api.get<IColor>(`/public/color/${id}`);
      return response;
    }),

    create: useAsync(async (newColor: IColor): Promise<IColor> => {
      auth.validate();
      const response = await api.post<IColor>("/moderator/color", newColor);
      return response;
    }),

    update: useAsync(
      async (id: number, updatedColor: IColor): Promise<IColor> => {
        auth.validate();
        const response = await api.put<IColor>(
          `/moderator/color/${id}`,
          updatedColor
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/color/${id}`);
    }),
  };

  return { color };
};
