import "./UnderlinedButton.scss";
import { IoIosArrowForward } from "react-icons/io";

export default function underlinedButton(props: {
  content: string;
  path: string;
  preview?: boolean;
}) {
  const { content, path, preview } = props;

  return (
    <a href={!preview ? path : undefined} className="a-underlinedButton">
      {content} <IoIosArrowForward />
    </a>
  );
}
