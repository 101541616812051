import "./ShowroomItem.scss";
import { FiPhone, FiMail } from "react-icons/fi";
import AdjustableButton from "../../../UI/AdjustableButton/AdjustableButton";
import { useLocation } from 'react-router';
import { useEffect } from 'react';

interface ShowroomItemProps {
  place: string;
  address: string;
  phone_number: string;
  email: string;
  path: string;
  hours_week: string;
  hours_weekend: string;
  ImgPath: string;
  vis_path: string;
  reverse?: boolean;
  id: string;
}

export default function ShowroomItem(props: ShowroomItemProps) {

  const location = useLocation(); 
  useEffect(() => { 
    const hash = location.hash; 
    if (hash) { 
      const element = document.getElementById(hash.substring(1)); 
      if (element) {
          element.scrollIntoView(); 
      } 
    } 
  }, [location]);

  const {
    place,
    address,
    phone_number,
    email,
    path,
    hours_week,
    hours_weekend,
    ImgPath,
    reverse = false,
    id,
  } = props;

  return (
    <div className={`showroom-item ${reverse ? "reverse" : ""}`} id={id}>
      <div className="showroom-text">
        <h2 className="place">{place}</h2>
        <p className="address">{address}</p>
        <h2 className="hours">GODZINY OTWARCIA</h2>
        <p className="p-hours">
          Pn-Sb <span>{hours_week}</span>
        </p>
        <p className="p-hours">
          Niedziela handlowa <span>{hours_weekend}</span>
        </p>
        <p className="mail">
          <FiMail />
          <a href={`mailto:${email}`}>WYŚLIJ E-MAIL</a>
        </p>
        <p className="call">
          <FiPhone />
          <a href={`tel:${phone_number}`}>ZADZWOŃ {phone_number}</a>
        </p>
        <AdjustableButton text={"Umów wizytę w salonie"} href={path} />
      </div>
      <div className="showroom-image">
        <img src={ImgPath} alt={place} />
      </div>
    </div>
  );
}
